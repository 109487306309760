import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { FormEvent, ReactElement } from "react";

export function GeneralModal(props: {
  title: string;
  body: ReactElement;
  buttonText?: string;
  extraButtonText?: string;
  setFieldValue?: any;
  onOpen: () => void;
  onClose: () => void;
  handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  isSubmitting?: boolean;
  isOpen: boolean;
  minW?: string;
}) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent w="fit-content" maxW="100%" minW={props.minW || "820px"}>
        <form onSubmit={props.handleSubmit}>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.body}</ModalBody>
          <ModalFooter>
            {props.handleSubmit && (
              <>
                <Button id="modal-save-btn" type="submit" isLoading={props.isSubmitting}>
                  {props.buttonText}
                </Button>
                {props.extraButtonText && props.setFieldValue && (
                  <Button
                    type="button"
                    onClick={(e) => {
                      if(!props.setFieldValue || !props.handleSubmit) return
                      props.setFieldValue("extra", true);
                      props.handleSubmit(e as any)
                    }}
                    value="extra"
                    colorScheme="yellow"
                    ml={1}
                    isLoading={props.isSubmitting}
                  >
                    {props.extraButtonText}
                  </Button>
                )}
              </>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
