import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import QRCode from "react-qr-code";
import {
  change_password,
  enable_mfa,
  forgot_password,
  get_enable_mfa,
  login,
  mfa,
} from "../../common/Api";
import { ErrorMessage } from "../../common/Components";
import { ChangePasswordSchema } from "../../common/Models";
import { PasswordField } from "./Password";

function signIn(
  email: string,
  password: string,
  setError: Function,
  setLoading: Function,
) {
  setLoading(true);
  setError(false);
  login({ email: email, password: password })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
    });
}

function changePassword(
  password: string,
  setError: Function,
  setLoading: Function,
) {
  setLoading(true);
  setError(false);
  change_password({ password: password, self: false })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
    });
}

function enableMFA(code: string, setError: Function, setLoading: Function) {
  setLoading(true);
  setError(false);
  enable_mfa({ code: code })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
    });
}

function sendMFA(code: string, setError: Function, setLoading: Function) {
  setLoading(true);
  setError(false);
  mfa({ code: code })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
    });
}

function forgotPassword(
  email: string,
  setError: Function,
  setLoading: Function,
  setSubmitted: Function,
) {
  forgot_password({ email: email })
    .then(() => {})
    .catch(() => {
      setError(true);
    })
    .finally(() => {
      setLoading(false);
      setSubmitted(true);
    });
}

export function LoginPage(props: { step: number }) {
  const [step, setStep] = useState(props.step);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [preMfa, setPreMfa] = useState("");

  const [mfa, setMfa] = useState("");

  const [mfaUrl, setMfaUrl] = useState("");

  useEffect(() => {
    if (step !== 2) return;
    get_enable_mfa().then((res) => setMfaUrl(res.data));
  }, [step]);

  const passwordForm = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      changePassword(values.password1, setError, passwordForm.setSubmitting);
    },
  });

  return (
    <Box
      bgGradient={{ sm: "linear(to-r, blue.600, blue.400)" }}
      py={{ base: "12", md: "24" }}
    >
      <Container
        maxW="md"
        py={{ base: "0", sm: "8" }}
        px={{ base: "4", sm: "10" }}
        bg={{ base: "transparent", sm: "bg.surface" }}
        boxShadow={{ base: "none", sm: "xl" }}
        borderRadius={{ base: "none", sm: "xl" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            {step === 0 && (
              <form>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      autoFocus
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <PasswordField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error && !loading && (
                    <ErrorMessage>Credentials are invalid</ErrorMessage>
                  )}
                </Stack>
                <HStack justify="end" my={6}>
                  <Button
                    variant="text"
                    size="sm"
                    onClick={() => {
                      setStep(4);
                    }}
                  >
                    Get a Login Link/Forgot password
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      signIn(email, password, setError, setLoading);
                    }}
                    isDisabled={loading}
                    isLoading={loading}
                    type="submit"
                  >
                    Sign in
                  </Button>
                </Stack>
              </form>
            )}
            {step === 1 && (
              <>
                <form onSubmit={passwordForm.handleSubmit}>
                  <FormLabel
                    htmlFor="pin"
                    textAlign="center"
                    fontWeight={"bold"}
                  >
                    Change Password
                  </FormLabel>
                  <FormControl
                    isInvalid={
                      !!(
                        passwordForm.errors.password1 &&
                        passwordForm.touched.password1
                      )
                    }
                  >
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password1"
                        onChange={passwordForm.handleChange}
                        onBlur={passwordForm.handleBlur}
                        value={passwordForm.values.password1}
                      />
                      <InputRightElement>
                        <IconButton
                          variant="text"
                          aria-label={
                            showPassword ? "Reveal password" : "Mask password"
                          }
                          icon={showPassword ? <HiEye /> : <HiEyeOff />}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {passwordForm.errors.password1}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!(
                        passwordForm.errors.password2 &&
                        passwordForm.touched.password2
                      )
                    }
                  >
                    <FormLabel>Password, repeated</FormLabel>
                    <InputGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        id="password2"
                        onChange={passwordForm.handleChange}
                        onBlur={passwordForm.handleBlur}
                        value={passwordForm.values.password2}
                      />
                      <InputRightElement>
                        <IconButton
                          variant="text"
                          aria-label={
                            showPassword ? "Reveal password" : "Mask password"
                          }
                          icon={showPassword ? <HiEye /> : <HiEyeOff />}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {passwordForm.errors.password2}
                    </FormErrorMessage>
                  </FormControl>
                  {error && !loading && (
                    <ErrorMessage>Something went wrong</ErrorMessage>
                  )}
                  <Stack spacing="6">
                    <Button
                      mt={3}
                      type="submit"
                      isLoading={passwordForm.isSubmitting}
                    >
                      Confirm
                    </Button>
                  </Stack>
                </form>
              </>
            )}
            {step === 2 && mfaUrl !== "" && (
              <>
                <FormLabel htmlFor="pin" textAlign="center" fontWeight={"bold"}>
                  Multi-Factor Authentication PIN
                </FormLabel>
                <Center>
                  <QRCode style={{ maxWidth: "200px" }} value={mfaUrl} />
                </Center>
                <Text>
                  You will need to download an application to generate
                  authentication codes on your smartphone. "Authenticator" by
                  Google is our suggestion.
                </Text>
                <HStack justify="center">
                  <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                    <Image
                      src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                      maxW={"200px"}
                    ></Image>
                  </Link>

                  <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">
                    <Image
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      maxW={"150px"}
                    ></Image>
                  </Link>
                </HStack>
                <HStack justify="center">
                  <PinInput
                    autoFocus
                    otp
                    id="pin"
                    value={preMfa}
                    onChange={setPreMfa}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <Stack spacing="6">
                  {error && !loading && (
                    <ErrorMessage>Invalid code</ErrorMessage>
                  )}
                  <Button
                    onClick={() => {
                      enableMFA(preMfa, setError, setLoading);
                    }}
                    isDisabled={loading || preMfa.length !== 6}
                    isLoading={loading}
                  >
                    Confirm
                  </Button>
                </Stack>
              </>
            )}
            {step === 3 && (
              <>
                <FormLabel htmlFor="pin" textAlign="center" fontWeight={"bold"}>
                  Multi-Factor Authentication PIN
                </FormLabel>
                <HStack justify="center">
                  <PinInput
                    autoFocus
                    otp
                    id="pin"
                    value={mfa}
                    onChange={setMfa}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <Stack spacing="6">
                  {error && !loading && (
                    <ErrorMessage>Invalid code</ErrorMessage>
                  )}
                  <Button
                    onClick={() => {
                      sendMFA(mfa, setError, setLoading);
                    }}
                    isDisabled={loading || mfa.length !== 6}
                    isLoading={loading}
                  >
                    Confirm
                  </Button>
                </Stack>
              </>
            )}
            {step === 4 && (
              <>
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      autoFocus
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack spacing="6">
                  <Button
                    onClick={() => {
                      forgotPassword(email, setError, setLoading, setSubmitted);
                    }}
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Email me the Link
                  </Button>
                  {submitted && (
                    <Text colorScheme="blue">
                      If the email you entered is valid, you will receive an
                      email shortly.
                    </Text>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
