import Cookies from "js-cookie";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { refresh } from "./common/Api";

const demo = process.env.REACT_APP_DEMO === "1";

interface MyJwt extends JwtPayload {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  change_password?: boolean;
  mfa?: boolean;
  pre_mfa?: boolean;
  roles?: { verb: number; resource: number }[];
}

export function getPermissionLevel(): number {
  if (demo) return 4;

  const token = Cookies.get("access_token");
  if (token === undefined) {
    const refresh_token = Cookies.get("refresh_token");
    if (refresh_token !== undefined) {
      refresh()
        .then(() => window.location.reload())
        .catch(() => window.location.replace("/"));
    }
    return 0;
  }

  const decoded = jwtDecode<MyJwt>(token);

  if (decoded.change_password) return 1;
  if (!decoded.mfa) return 2;
  if (decoded.pre_mfa) return 3;

  return 4;
}

export function getPermissionList(): { verb: number; resource: number }[] {
  const none = [
    { resource: 0, verb: 0 },
    { resource: 1, verb: 0 },
    { resource: 2, verb: 0 },
    { resource: 3, verb: 0 },
    { resource: 4, verb: 0 },
    { resource: 5, verb: 0 },
  ];
  if (demo) {
    return [
      { resource: 0, verb: 7 },
      { resource: 1, verb: 7 },
      { resource: 2, verb: 7 },
      { resource: 3, verb: 7 },
      { resource: 4, verb: 7 },
      { resource: 5, verb: 7 },
    ];
  }
  const token = Cookies.get("access_token");
  if (token === undefined) {
    const refresh_token = Cookies.get("refresh_token");
    if (refresh_token !== undefined) {
      refresh()
        .then(() => window.location.reload())
        .catch(() => window.location.replace("/"));
    }
    return none;
  }

  const decoded = jwtDecode<MyJwt>(token);
  return decoded.roles || none;
}
