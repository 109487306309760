import {
  Box,
  Container,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { get_logs } from "../../common/Api";
import { LoadingSpinner } from "../../common/Components";
import { Log } from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";

export function LogsView() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);

  const [, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, , query, sort] = useSearch();

  const [logs, setLogs] = useState(new Array<Log>());
  const [totalCount, setTotalCount] = useState(1);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_logs(page as number, pageSize as number, query as string, sort as string)
      .then((res) => {
        const d = res.data.logs as Array<Log>;
        const t = res.data.total;
        setTotalCount(t);
        setLogs(d);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }} mt={10}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box overflowX="auto">
            <MyTable<Log>
              sortColumn={sort as string}
              setSearchParams={setSearchParams}
              columns={[
                { name: "Time", width: "25%", sort_id: "time" },
                { name: "Action", width: "17%" },
                { name: "Resource", width: "20%" },
                { name: "User", width: "20%" },
                { name: "Result", width: "20%" },
              ]}
              data={logs}
              bindings={[
                (r: Log) => r.time,
                (r: Log) => actionToString(r.action),
                (r: Log) => r.resource,
                (r: Log) => r.user || "Unknown",
                (r: Log) => resultToString(r.result),
              ]}
              rk={(r: Log) => `${r.id}`}
              functions={[]}
            />
          </Box>
          <Box bg="bg.surface">
            <Container>
              <Pagination
                count={totalCount}
                pageSize={pageSize as number}
                siblingCount={2}
                page={page as number}
                onChange={(e: any) => {
                  setSearchParams((params) => {
                    params.set("page", e.page);
                    return params;
                  });
                }}
              />
            </Container>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
              onChange={(e) => {
                setSearchParams((params) => {
                  params.set("pageSize", e.target.value);
                  return params;
                });
              }}
              value={pageSize}
            >
              <option value="10">10 Results per Page</option>
              <option value="20">20 Results per Page</option>
              <option value="30">30 Results per Page</option>
              <option value="50">50 Results per Page</option>
              <option value="100">100 Results per Page</option>
            </select>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

function actionToString(val: number): string {
  switch (val) {
    case 1:
      return "Read/List";
    case 2:
      return "Edit";
    case 3:
      return "Edit";
    case 4:
      return "Add";
    case 5:
      return "Delete";
  }

  return `Unknown (${val})`;
}

function resultToString(val: number): string {
  const cat = val / 100;
  switch (val) {
    case 401:
      return "Unauthorized";
    case 403:
      return "Unauthorized";
  }

  switch (cat) {
    case 2:
      return "Succeeded";
    case 4:
      return "User Error";
    case 5:
      return "Server Error (Bug)";
  }

  return `Unknown (${cat})`;
}
