import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { login_link } from "../../common/Api";

export function LoginLink() {
  let [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const token = searchParams.get("token");
  useEffect(() => {
    if (token === null) {
      return;
    }
    login_link({ token: token })
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        setMessage("Link has expired");
      });
  }, [token]);
  return <Text size="xl">{message}</Text>;
}
