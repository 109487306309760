import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { change_password } from "../../common/Api";
import { ChangePasswordSchema } from "../../common/Models";

export function Settings() {
  const passwordForm = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      change_password({ password: values.password1, self: true }).then(() => {
        window.location.reload();
      });
    },
  });

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text textStyle="lg" fontWeight="medium">
              Settings
            </Text>
          </Box>
        </Stack>
        <Divider />
        <form onSubmit={passwordForm.handleSubmit}>
          <Stack spacing="5" divider={<StackDivider />}>
            <FormControl
              isInvalid={
                !!(
                  passwordForm.errors.password1 &&
                  passwordForm.touched.password1
                )
              }
            >
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">New Password</FormLabel>
                <Input
                  id="password1"
                  type="password"
                  maxW={{ md: "3xl" }}
                  onChange={passwordForm.handleChange}
                  onBlur={passwordForm.handleBlur}
                  value={passwordForm.values.password1}
                />
              </Stack>
              <FormErrorMessage>
                {passwordForm.errors.password1}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!(
                  passwordForm.errors.password2 &&
                  passwordForm.touched.password2
                )
              }
            >
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "1.5", md: "8" }}
                justify="space-between"
              >
                <FormLabel variant="inline">New Password, repeated</FormLabel>
                <Input
                  id="password2"
                  type="password"
                  maxW={{ md: "3xl" }}
                  onChange={passwordForm.handleChange}
                  onBlur={passwordForm.handleBlur}
                  value={passwordForm.values.password2}
                />
              </Stack>
              <FormErrorMessage>
                {passwordForm.errors.password2}
              </FormErrorMessage>
            </FormControl>
            <Flex direction="row-reverse">
              <Button type="submit">Save</Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
