import * as Yup from "yup";

export interface Question {
  id: number;
  project_id: number;
  question: string;
  kind: number;
  options?: string;
}

export interface Answer {
  patient_id: number;
  question_id: number;
  answer_text: string;
  answer_number: number;
}

export interface User {
  email: string;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
  permissions: number[];
  activated: boolean;
}

export interface Log {
  id: number;
  action: number;
  resource: string;
  result: number;
  time: string;
  user: string;
}

export interface Pump {
  id: number;
  name: string;
  number: string;
  charger: string;
  lost: boolean;
  kind?: number;
  calibrations: (Calibration & Tube & { tube_id: number })[];
  notes: string;
  locations: (Site & Transaction)[];
}

export interface Calibration {
  id: number;
  date: Date;
  reading_before: number;
  reading_after: number;
  target_reading: number;
  notes: string;
}

export interface Kit {
  id: number;
  number: string;
  lost: boolean;
  make_date?: string | null;
  bag_conditioned?: string | null;
  kind?: number;
  tubes: Tube[];
  tube_history: (Tube & {status: string; created_at: string})[];
  notes: string;
  locations: (Site & Transaction)[];
  location: Site & Transaction;
}

export interface Tube {
  id: number;
  asset: Kit;
  number: string;
  part_number: string;
  flow_rate: number;
  state: number;
  kind: number;
  notes: string;
  conditions: Condition[];
  history: TubeHistory[];
}

export interface TubeHistory {
  event_id: number;
  created_at: string;
  prev_kit_id: number;
  prev_kind: number;
  prev_state: number;
  new_kit_id: number;
  new_kind: number;
  new_state: number;
}

export interface Condition {
  passed: boolean;
  run_date: string;
  date: string;
  notes: string;
}

export interface User {
  email: string;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
  permissions: number[];
  activated: boolean;
}

export interface Site {
  id: number;
  code: string;
  project_id: number;
  name: string;
  notes: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  prompt: string;
  project: Project;
  assets: ((Pump | Kit) & Transaction)[];
  transactions: Transaction[];
  files: File[];
  patients: Patient[];
  recruited_patients: number;
}

export interface Project {
  id: number;
  title: string;
  expected_recruitment: number;
  actual_recruitment?: number;
  start_date: string;
  end_date: string;
  sites: Site[];
  notes: string;
  survey: Question[];
  files: File[];
}

export interface File {
  id: number;
  name: string;
  created_at: string;
  size: number;
  uploaded_by: string;
  notes: string;
  project_id: number;
  project: Project | null;
  patient_id: string;
  site_id: number;
  site: Site | null;
}

export interface Patient {
  id: string;
  site_id: number;
  project_id?: number;
  site?: Site;
  project?: Project;
  sex_at_birth: number;
  age: number;
  birth_country?: string;
  height: number;
  weight: number;
  bmi: number;
  answers: (Answer & Question)[];
  created_at: Date;
  files: File[];
}

export interface Sample {
  id: number; // AutoField(primary_key=True)
  patient_id: number; // ForeignKeyField(PatientModel, column_name="patient_id", backref="samples")

  // Eligibility
  // over_18_years: boolean;
  // can_breathe_into_sampling_apparatus: boolean;
  // willing_to_participate: boolean;
  // meets_criteria: string; // For TB disease, TB infection, or control/contact
  // received_tb_medication: boolean;
  // unable_to_provide_consent: boolean;
  // respiratory_distress: boolean;

  // General Patient Information
  height: number;
  weight: number;
  bmi: number;

  // Patient TB Information
  had_tb_in_past: boolean;
  past_tb_approx_date: string | null; // Month/Year
  tb_treated: boolean;
  duration_of_tb_treatment: string | null;
  currently_has_tb: number;
  tb_or_respiratory_symptoms: boolean;
  symptoms_of_tb_at_enrolment: string; // List of symptoms

  // Medical History
  hiv_status: number;
  hiv_diagnosis_year: number | null;
  hiv_cd4_count: number | null;
  recent_cd4_count_date: Date | null;
  hiv_viral_load: number | null;
  recent_viral_load_date: Date | null;
  antiretroviral_therapy: boolean;
  antiretrovirals_taken: string | null;
  on_other_medication: boolean;
  other_medications_list: string | null;
  diabetes_status: boolean;
  recent_hba1c: number | null;
  smoking_status: number;
  smoking_start_age: number | null;
  smoking_packs_per_day: number | null;
  smoking_stop_age: number | null;
  alcohol_frequency: number;

  // Microbiology
  microbiology_required: boolean;
  microbiology_test_date: Date | null;
  spontaneous_sputum_samples: number | null;
  induced_sputum_samples: number | null;
  gastric_aspirate_samples: number | null;
  afb_smear_done: number | null;
  microbiology_results: number;
  number_of_cultures_done: number | null;
  cultures_results: number;
  genexpert_done: boolean;
  genexpert_results: number;

  // Patient Breath Collection
  breath_collection_attempted: boolean;
  reason_no_breath_collection: string | null;
  nose_plug_used: boolean;
  reason_no_nose_plug: string | null;
  mouth_rinse_with_water: boolean;
  reason_no_mouth_rinse: string | null;
  breath_collection_start_time: string | null;
  breath_collection_end_time: string | null;
  breath_bag_filled: boolean;
  difficulty_with_procedure: string | null;
  reason_for_difficulty: string | null;
  breath_sample_collection_successful: boolean;
  reason_sample_unsuccessful: string | null;
  sampling_tube_serial_number_1: string | null;
  sampling_tube_serial_number_2: string | null;
  time_between_collection_and_transfer: number | null;

  // Dietary Information
  breakfast_time: string | null;
  breakfast_details: string | null;
  lunch_time: string | null;
  lunch_details: string | null;
  other_food_time: string | null;
  other_food_details: string | null;
  corn_time: string | null;
  eggs_time: string | null;
  tuna_time: string | null;
  gum_time: string | null;
  toothpaste_time: string | null;
  beer_time: string | null;
  wine_time: string | null;
  marijuana_time: string | null;
  tobacoo_time: string | null;
  vape_time: string | null;

  // Home Remedies and Medications
  home_remedies_time: string | null;
  home_remedies_details: string | null;
  otc_medications_time: string | null;
  otc_medications_details: string | null;
}

interface Transaction {
  id: number;
  from: number;
  to: number;
  date_sent: string;
  date_received?: string;
  tracking_number: string;
  pump_id: number;
  kit_id: number;
  kit_number: string;
  pump_name?: string;
  bulk_id?: string | null;
}

export const AddPatientSchema = Yup.object().shape({
  id: Yup.string().required("Patient ID is required"),
  project_id: Yup.number().required("Required").min(1, "Required"),
  site_id: Yup.number().required("Required").min(1, "Required"),
  sex_at_birth: Yup.number()
    .oneOf([0, 1], "Please choose an option")
    .required(),
  age: Yup.number()
    .min(18, "Patient should at least be 18")
    .max(130, "Patient cannot be that old")
    .required("Please enter the age of patient"),
  birth_country: Yup.string(),
  height: Yup.number().required().min(0.1, "Weight should be more than zero"),
  weight: Yup.number().required().min(0.1, "Height should be more than zero"),
  bmi: Yup.number().required().min(0.1, "BMI should be more than zero"),
});

export const AddSiteSchema = Yup.object().shape({
  code: Yup.string().min(0),
  name: Yup.string().required("Required"),
  project_id: Yup.number().min(1, "Required").required("Required"),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  address1: Yup.string().min(0),
  address2: Yup.string().min(0),
  city: Yup.string().min(0),
  state: Yup.string().min(0),
  country: Yup.string().min(0),
  postal_code: Yup.string().min(0),
  contact_name: Yup.string().min(0),
  contact_email: Yup.string().min(0),
  contact_phone: Yup.string().min(0),
  prompt: Yup.string().min(0),
});

export const EditSiteSchema = Yup.object().shape({
  code: Yup.string().min(0),
  name: Yup.string().required("Required"),
  project_id: Yup.number().min(1, "Required").required("Required"),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  address1: Yup.string().min(0),
  address2: Yup.string().min(0),
  city: Yup.string().min(0),
  state: Yup.string().min(0),
  country: Yup.string().min(0),
  postal_code: Yup.string().min(0),
  contact_name: Yup.string().min(0),
  contact_email: Yup.string().min(0),
  contact_phone: Yup.string().min(0),
  prompt: Yup.string().min(0),
});

export const AddProjectSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  expected_recruitment: Yup.number().required("Required"),
  start_date: Yup.date().required("Required"),
  end_date: Yup.date()
    .required("Required")
    .when(["start_date"], (start_date, schema) =>
      schema.min(start_date, "End date cannot be earlier than start date"),
    ),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  survey: Yup.array()
    .min(0)
    .required("Required")
    .of(
      Yup.object().shape({
        question: Yup.string().required("Required"),
        kind: Yup.number().required().min(1, "Choose a kind"),
        options: Yup.string().when("kind", {
          is: (val: number) => val === 1 || val === 2,
          then: (schema) =>
            schema
              .required("Required")
              .test(
                "is-multiple-options",
                "Not enough options",
                (value) => value.replace(/,+$/g, "").split(",").length > 1,
              ),
        }),
      }),
    ),
});
export const EditProjectSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  expected_recruitment: Yup.number().required("Required"),
  start_date: Yup.date().required("Required"),
  end_date: Yup.date()
    .required("Required")
    .when(["start_date"], (start_date, schema) =>
      schema.min(start_date, "End date cannot be earlier than start date"),
    ),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  survey: Yup.array()
    .min(0)
    .required("Required")
    .of(
      Yup.object().shape({
        question: Yup.string().required("Required"),
        kind: Yup.number().required().min(1, "Choose a kind"),
        options: Yup.string().when("kind", {
          is: (val: number) => val === 1 || val === 2,
          then: (schema) =>
            schema
              .required("Required")
              .test(
                "is-multiple-options",
                "Not enough options",
                (value) => value.replace(/,+$/g, "").split(",").length > 1,
              ),
        }),
      }),
    ),
});

export const AddPumpSchema = Yup.object().shape({
  name: Yup.string().min(0).required("Required"),
  number: Yup.string().min(0).required("Required"),
  charger: Yup.string(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  site: Yup.number()
    .required("You have to choose an initial site")
    .min(1, "You have to choose an initial site"),
});

export const EditPumpSchema = Yup.object().shape({
  name: Yup.string().min(0).required("Required"),
  number: Yup.string().min(0).required("Required"),
  charger: Yup.string(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const AddKitSchema = Yup.object().shape({
  number: Yup.string().min(0).required("Required"),
  make_date: Yup.date().nullable().optional(),
  bag_conditioned: Yup.date().nullable().optional(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
  site: Yup.number()
    .required("You have to choose an initial site")
    .min(1, "You have to choose an initial site"),
});

export const EditKitSchema = Yup.object().shape({
  number: Yup.string().min(0).required("Required"),
  make_date: Yup.date().nullable().optional(),
  bag_conditioned: Yup.date().nullable().optional(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const AddTubeSchema = Yup.object().shape({
  kit_id: Yup.number().min(0).optional(),
  number: Yup.string().required("Required"),
  part_number: Yup.string().required("Required"),
  flow_rate: Yup.number().required("Required"),
  kind: Yup.number().min(1, "Required").required("Required"),
  state: Yup.number().min(0).required("Required"),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const EditTubeSchema = Yup.object().shape({
  kit_id: Yup.number().min(0).optional(),
  number: Yup.string().required("Required"),
  part_number: Yup.string().required("Required"),
  flow_rate: Yup.number().required("Required"),
  state: Yup.number().min(0).required("Required"),
  kind: Yup.number().min(1, "Required").required("Required"),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const ConditionTubeSchema = Yup.object().shape({
  passed: Yup.boolean().required(),
  run_date: Yup.date().required(),
  date: Yup.date().required(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const BulkConditioningSchema = Yup.object().shape({
  tubes: Yup.string()
    .required("Tube numbers are required (example: 72111,72333-72444).")
    .test(
      "is-proper",
      "You can only use numerics, commas, and dashes (example: 72111,72333-72444).",
      (v) => new RegExp("^[0-9][0-9,-]*$").test(v),
    )
    .test(
      "is-proper-range",
      "One of the ranges contains has the start greater than the end",
      (v) => {
        return v
          .split(",")
          .map((v: string) => {
            v = v.trim();
            if (!v.includes("-")) {
              return !isNaN(parseInt(v));
            }

            const [s1, s2] = v.split("-");
            const n1 = parseInt(s1);
            const n2 = parseInt(s2);

            if (isNaN(n1) || isNaN(n2)) return false;
            if (n2 < n1) return false;

            return true;
          })
          .every((v) => v === true);
      },
    ),
  passed: Yup.boolean().required(),
  run_date: Yup.date().required(),
  date: Yup.date().required(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const BulkStateSchema = Yup.object().shape({
  tubes: Yup.string()
    .required("Tube numbers are required (example: 72111,72333-72444).")
    .test(
      "is-proper",
      "You can only use numerics, commas, and dashes (example: 72111,72333-72444).",
      (v) => new RegExp("^[0-9][0-9,-]*$").test(v),
    )
    .test(
      "is-proper-range",
      "One of the ranges contains has the start greater than the end",
      (v) => {
        return v
          .split(",")
          .map((v: string) => {
            v = v.trim();
            if (!v.includes("-")) {
              return !isNaN(parseInt(v));
            }

            const [s1, s2] = v.split("-");
            const n1 = parseInt(s1);
            const n2 = parseInt(s2);

            if (isNaN(n1) || isNaN(n2)) return false;
            if (n2 < n1) return false;

            return true;
          })
          .every((v) => v === true);
      },
    ),
  kind: Yup.number().min(1, "Required").required("Required"),
  state: Yup.number().min(0).required("Required"),
  kit_id: Yup.number().min(0).optional(),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const AddUserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .length(10, "Phone number should consist of 10 digits only")
    .required("Required"),
  permissions: Yup.array().of(Yup.number()).length(6),
});

export const EditUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: Yup.string()
    .length(10, "Phone number should consist of 10 digits only")
    .required("Required"),
  roles: Yup.array()
    .of(Yup.object({ resource: Yup.number(), verb: Yup.number() }))
    .min(0),
});

export const AddFileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  notes: Yup.string()
    .min(0)
    .max(255, "Notes should be shorter than 256 characters"),
});

export const ShipSchema = Yup.object().shape({
  frm: Yup.number().min(1, "Please select a site").required("Required"),
  to: Yup.number()
    .transform((v) => (isNaN(v) ? 0 : v))
    .min(1, "Please select a site")
    .required("Required"),
  sent: Yup.date().required("Required"),
  received: Yup.date()
    .when(["sent"], (sent, schema) =>
      schema.min(sent, "Receipt date cannot be earlier than send date"),
    )
    .optional()
    .nullable(),
  tracking_number: Yup.string().optional().nullable(),
  notes: Yup.string().optional().nullable(),
});

export const BulkShipSchema = Yup.object().shape({
  assets: Yup.array()
    .of(Yup.string())
    .min(1, "You have to select one asset at least"),
  frm: Yup.number()
    .transform((v) => (isNaN(v) ? 0 : v))
    .min(1, "Please select a site")
    .required("Required"),
  to: Yup.number()
    .transform((v) => (isNaN(v) ? 0 : v))
    .min(1, "Please select a site")
    .required("Required"),
  sent: Yup.date().required("Required"),
  received: Yup.date()
    .when(["sent"], (sent, schema) =>
      schema.min(sent, "Receipt date cannot be earlier than send date"),
    )
    .optional()
    .nullable(),
  tracking_number: Yup.string().optional().nullable(),
  notes: Yup.string().optional().nullable(),
});

export const ChangePasswordSchema = Yup.object({
  password1: Yup.string()
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 10 characters, one uppercase, one number and one special case character",
    )
    .required("Password is required"),
  password2: Yup.string().oneOf([Yup.ref("password1")], "Passwords must match"),
});

export function validateCSV(
  csv: Papa.ParseResult<any>,
  expectedColumns: Array<string>,
): Array<string> {
  if (!csv) return ["Cannot read csv file"];
  if (!csv.meta.fields) return ["Missing header/column names"];

  const existingColumns = new Set(csv.meta.fields.map((v) => v.toLowerCase()));
  const difference = new Set(
    [...expectedColumns].filter((x) => !existingColumns.has(x)),
  );
  const diffValues = Array.from(difference.values());

  if (diffValues.length !== 0) {
    return diffValues.map((v) => `Column '${v}' is missing`);
  }

  return [];
}
