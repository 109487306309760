import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { FiEdit2, FiSearch, FiTrash2 } from "react-icons/fi";
import { RiTestTubeFill } from "react-icons/ri";
import { BiDetail } from "react-icons/bi";
import {
  add_tube,
  bulk_condition_tubes,
  bulk_state,
  condition_tube,
  delete_tube,
  edit_tube,
  get_kits,
  get_tube,
  get_tubes,
  import_csv,
} from "../../common/Api";
import {
  AddTubeSchema,
  BulkConditioningSchema,
  BulkStateSchema,
  ConditionTubeSchema,
  EditTubeSchema,
  Kit,
  Tube,
  validateCSV,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import { TODAY } from "./pumps";
import { download_csv_sample, LoadingSpinner } from "../../common/Components";
import { useSearchParams } from "react-router-dom";
import Papa from "papaparse";
import { getPermissionList } from "../../token";
enum ModalModes {
  ADD,
  EDIT,
  DETAILS,
  CONDITION,
  BULK_CONDITIONING,
  BULK_STATE,
}

const STATES = [
  "In Fridge - No Sample",
  "In a Kit",
  "In Analysis",
  "In Fridge - Has a Sample",
  "Other",
];
const KINDS = ["Calibration Tube", "Sampling Tube", "Research Tube"];

export function TubeManagement() {
  const toast = useToast();

  const permissions = getPermissionList();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [tube, setTube] = useState(null as Tube | null);

  const [totalCount, setTotalCount] = useState(0);
  const [tubes, setTubes] = useState(new Array<Tube>());
  const [allTubes, setAllTubes] = useState(new Array<Tube>());
  const [kits, setKits] = useState(new Array<Kit>());

  const [queryBuffer, setQueryBuffer] = useState(query as string);
  const [timeout, setTO] = useState(null as NodeJS.Timeout | null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        return params;
      });
      setTube(null);
    },
  });

  const createForm = useFormik({
    initialValues: {
      kit_id: 0,
      number: "",
      part_number: "",
      flow_rate: 0,
      state: 0,
      kind: 0,
      notes: "",
    },
    validationSchema: AddTubeSchema,
    onSubmit: (values) => {
      toast.promise(
        add_tube(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
          createForm.resetForm();
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      kit_id: 0,
      number: "",
      part_number: "",
      flow_rate: 0,
      state: 0,
      kind: 0,
      notes: "",
    },
    validationSchema: EditTubeSchema,
    onSubmit: (values) => {
      toast.promise(
        edit_tube(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  const conditionForm = useFormik({
    initialValues: { passed: false, run_date: TODAY, date: TODAY, notes: "" },
    validationSchema: ConditionTubeSchema,
    onSubmit: (values) => {
      toast.promise(
        condition_tube(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Conditioned" },
          error: {
            title: "Failed to Condition",
            description: "Please contact support",
          },
          loading: { title: "Conditioning..." },
        },
      );
    },
  });

  const bulkConditioningForm = useFormik({
    initialValues: {
      tubes: "",
      passed: false,
      run_date: TODAY,
      date: TODAY,
      notes: "",
    },
    validationSchema: BulkConditioningSchema,
    onSubmit: (values) => {
      const { tubes, ...body }: any = values;
      const tubeList: Array<string> = [];

      tubes.split(",").forEach((v: string) => {
        v = v.trim();
        if (!v.includes("-")) {
          tubeList.push(v);
          return;
        }

        const [s1, s2] = v.split("-");
        const n1 = parseInt(s1);
        const n2 = parseInt(s2);
        for (var i = n1; i <= n2; i++) {
          var newV = i.toString();
          if (newV.length < s2.length) {
            newV = newV.padStart(s2.length - newV.length, "0");
          }
          tubeList.push(newV);
        }
      });

      body.tubes = tubeList;
      toast.promise(
        bulk_condition_tubes(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Bulk Conditioned" },
          error: {
            title: "Failed to Bulk Condition",
            description: "Please contact support",
          },
          loading: { title: "Conditioning..." },
        },
      );
    },
  });
  const bulkStateForm = useFormik({
    initialValues: {
      tubes: "",
      state: 0,
      kind: 0,
    },
    validationSchema: BulkStateSchema,
    onSubmit: (values) => {
      const { tubes, ...body }: any = values;
      const tubeList: Array<string> = [];

      tubes.split(",").forEach((v: string) => {
        v = v.trim();
        if (!v.includes("-")) {
          tubeList.push(v);
          return;
        }

        const [s1, s2] = v.split("-");
        const n1 = parseInt(s1);
        const n2 = parseInt(s2);
        for (var i = n1; i <= n2; i++) {
          var newV = i.toString();
          if (newV.length < s2.length) {
            newV = newV.padStart(s2.length - newV.length, "0");
          }
          tubeList.push(newV);
        }
      });

      body.tubes = tubeList;
      toast.promise(
        bulk_state(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Bulk State Modify" },
          error: {
            title: "Failed to Bulk Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });
  useEffect(() => {
    switch (mode) {
      case ModalModes.EDIT:
        get_tube(id as number)
          .then((res) => {
            const tube = res.data;
            setTube(tube);
            editForm.setValues({
              kit_id: tube.kit_id || 0,
              number: tube.number || "",
              part_number: tube.part_number || "",
              flow_rate: tube.flow_rate || 0,
              state: tube.state || 0,
              kind: tube.kind || 0,
              notes: tube.notes || "",
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.DETAILS:
        get_tube(id as number)
          .then((res) => {
            const tube = res.data;
            setTube(tube);
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case ModalModes.CONDITION:
        conditionForm.resetForm();
        break;
      case ModalModes.BULK_CONDITIONING:
        bulkConditioningForm.resetForm();
        break;
      case ModalModes.BULK_STATE:
        bulkStateForm.resetForm();
        break;
      case -1:
        return;
    }
    onOpen();
  }, [searchParams, useSearchParams]);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_tubes(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.tubes as Array<Tube>;
        const t = res.data.total;
        setTubes(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  useEffect(() => {
    get_kits(1, 0, "", "").then((res) => {
      const d = res.data.kits as Array<Kit>;
      setKits(d);
    });

    get_tubes(1, 0, "", "").then((res) => {
      const d = res.data.tubes as Array<Tube>;
      setAllTubes(d);
    });
  }, []);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack direction={{ base: "column", md: "row" }} justify="end">
              {(permissions[4].verb & 1) != 0 && (
                <InputGroup maxW="xs">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return;

                      setSearchParams((params) => {
                        params.set("query", queryBuffer);
                        params.set("page", "1");
                        return params;
                      });
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setQueryBuffer(e.target.value);
                    }}
                    value={queryBuffer}
                  />
                </InputGroup>
              )}
              {(permissions[4].verb & 2) != 0 && (
                <>
                  <FormControl
                    colorScheme="blue"
                    justifyContent="end"
                    display="flex"
                  >
                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-l-lg text-sm font-bold px-2.5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700"
                      title="Download a Sample CSV"
                      onClick={() => {
                        download_csv_sample("tubes");
                      }}
                    >
                      i
                    </button>
                    <FormLabel
                      htmlFor="file_button"
                      textColor="white"
                      backgroundColor="#5728b5"
                      cursor="pointer"
                      py={2.5}
                      px={4}
                      rounded={5}
                      roundedLeft={0}
                      m={0}
                      w="max-content"
                      fontWeight="bold"
                      _hover={{
                        backgroundColor: "#6530cf",
                      }}
                    >
                      Import CSV
                    </FormLabel>
                    <Input
                      id="file_button"
                      name="file"
                      type="file"
                      display="none"
                      accept=".csv"
                      onChange={(e) => {
                        const files = e.currentTarget.files;
                        if (!files) return;

                        const file = files[0];
                        if (file === null || !file) return;

                        const reader = new FileReader();
                        reader.onload = ({ target }) => {
                          if (!target) return;
                          const columns = [
                            "number",
                            "part_number",
                            "flow_rate",
                            "kind",
                            "state",
                            "kit",
                          ];
                          const csv = Papa.parse<any>(target.result as string, {
                            header: true,
                          });
                          const errors = validateCSV(csv, columns);
                          if (errors.length !== 0) {
                            toast({
                              title: "Errors loading csv file",
                              description: errors.join(" | "),
                              status: "error",
                            });
                            return;
                          }

                          const tid = toast({
                            title: "Importing ...",
                            status: "loading",
                            duration: null,
                            onCloseComplete: () => window.location.reload(),
                          });
                          import_csv({
                            resource: "tubes",
                            data: target.result as string,
                          })
                            .then(() => {
                              toast.update(tid, {
                                status: "success",
                                title: "Finished importing successfully",
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            })
                            .catch((err) => {
                              toast.update(tid, {
                                status: "error",
                                title: "Failed to import",
                                description: err.response.data.join(" | "),
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            });
                        };

                        reader.readAsText(file);
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.BULK_STATE.toString());
                        return params;
                      });
                    }}
                    id="bulk_state"
                  >
                    Bulk State Change
                  </Button>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set(
                          "mode",
                          ModalModes.BULK_CONDITIONING.toString(),
                        );
                        return params;
                      });
                    }}
                    id="bulk_conditioning"
                  >
                    Bulk Conditioning
                  </Button>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.ADD.toString());
                        return params;
                      });
                    }}
                    id="add"
                  >
                    Add Tube
                  </Button>
                </>
              )}
            </Stack>
          </Box>
          {(permissions[4].verb & 1) != 0 && (
            <>
              <Box overflowX="auto">
                <MyTable<Tube>
                  sortColumn={sort as string}
                  setSearchParams={setSearchParams}
                  rowAction={(p) => {
                    setSearchParams((params) => {
                      params.set("id", p.id.toString());
                      params.set("mode", ModalModes.DETAILS.toString());
                      return params;
                    });
                  }}
                  columns={[
                    { name: "Tube Serial No", width: "15%", sort_id: "number" },
                    {
                      name: "Part Number",
                      width: "15%",
                      sort_id: "part_number",
                    },
                    { name: "Flow Rate", width: "10%", sort_id: "flow_rate" },
                    { name: "Kind", width: "15%", sort_id: "kind" },
                    { name: "State", width: "10%", sort_id: "state" },
                    { name: "Kit", width: "15%" },
                    { name: "Current Location", width: "15%" },
                    { name: "Actions", width: "15%" },
                  ]}
                  data={tubes}
                  bindings={[
                    (r) => `${r.number}`,
                    (r) => `${r.part_number}`,
                    (r) => `${r.flow_rate}`,
                    (r) => `${KINDS[r.kind - 1]}`,
                    (r) => (r.state ? `${STATES[r.state - 1]}` : "N/A"),
                    (r) =>
                      r.asset ? (
                        <Link href={`/kits?id=${r.asset.id}&mode=2`} isExternal>
                          {r.asset.number}
                          <ExternalLinkIcon mx="2px" />
                        </Link>
                      ) : (
                        "N/A"
                      ),
                    ({ asset: r }) => {
                      if (!r) return <></>;
                      if (r.lost) return <Badge colorScheme="red">Lost</Badge>;
                      return (
                        <>
                          {!r.location.date_received && (
                            <Badge colorScheme="green" mr={2}>
                              In-Transit {"->"}
                            </Badge>
                          )}
                          <Link
                            href={`/sites?id=${r.location.id}&mode=2`}
                            isExternal
                            onClick={(e) => e.stopPropagation()}
                          >
                            {`${r.location.name}`} <ExternalLinkIcon mx="2px" />
                          </Link>
                        </>
                      );
                    },
                  ]}
                  rk={(r: Tube) => `${r.id}`}
                  functions={[
                    {
                      title: "Edit",
                      icon: <FiEdit2 />,
                      condition: () => (permissions[4].verb & 2) != 0,
                      fn: (p: Tube) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.EDIT.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Condition",
                      icon: <RiTestTubeFill />,
                      condition: () => (permissions[4].verb & 2) != 0,
                      fn: (p: Tube) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.CONDITION.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Details",
                      icon: <BiDetail />,
                      fn: (p) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.DETAILS.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Delete",
                      icon: <FiTrash2 color="darkred" />,
                      condition: () => (permissions[4].verb & 4) != 0,
                      fn: (p) => {
                        const yes = window.confirm(
                          `Are you sure you want to delete the tube "${p.number}"? This action cannot be undone`,
                        );

                        if (!yes) return;

                        toast.promise(
                          delete_tube(p.id).then(() => {
                            setSearchParams((params) => {
                              params.set("mode", "-1");
                              return params;
                            });
                            window.location.reload();
                          }),
                          {
                            success: { title: "Successfully Deleted" },
                            error: {
                              title: "Failed to Delete Tube",
                              description: "Please contact support",
                            },
                            loading: { title: "Deleting..." },
                          },
                        );
                      },
                    },
                  ]}
                />
              </Box>
              <Box bg="bg.surface">
                <Container>
                  <Pagination
                    count={totalCount}
                    pageSize={pageSize as number}
                    siblingCount={2}
                    page={page as number}
                    onChange={(e: any) => {
                      setSearchParams((params) => {
                        params.set("page", e.page);
                        return params;
                      });
                    }}
                  />
                </Container>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                  onChange={(e) => {
                    setSearchParams((params) => {
                      params.set("pageSize", e.target.value);
                      return params;
                    });
                  }}
                  value={pageSize}
                >
                  <option value="10">10 Results per Page</option>
                  <option value="20">20 Results per Page</option>
                  <option value="30">30 Results per Page</option>
                  <option value="50">50 Results per Page</option>
                  <option value="100">100 Results per Page</option>
                </select>
              </Box>
            </>
          )}
        </Stack>
      </Box>
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          body={
            <AddTubeElement formik={createForm} disabled={[]} kits={kits} />
          }
          title={"Add Tube"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={<AddTubeElement formik={editForm} disabled={[]} kits={kits} />}
          title={"Edit Tube"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.CONDITION && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={conditionForm.handleSubmit}
          body={<ConditionTubeElement formik={conditionForm} disabled={[]} />}
          title={"Condition Tube"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 &&
        mode === ModalModes.BULK_CONDITIONING && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={bulkConditioningForm.handleSubmit}
            body={
              <BulkConditioningElement
                formik={bulkConditioningForm}
                disabled={[]}
                tubes={allTubes}
              />
            }
            title={"Bulk Condition Tubes"}
            buttonText="Save"
          />
        )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.BULK_STATE && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={bulkStateForm.handleSubmit}
          body={
            <BulkStateElement
              formik={bulkStateForm}
              disabled={[]}
              tubes={allTubes}
              kits={kits}
            />
          }
          title={"Bulk State Change"}
          buttonText="Save"
        />
      )}
      {mode === ModalModes.DETAILS && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={<DetailsElement tube={tube} kits={kits} />}
          title={"Tube Details"}
        />
      )}
    </Container>
  );
}

function AddTubeElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;
  const kits: Array<Kit> = props.kits;

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="number"
                isInvalid={formik.errors.number && formik.touched.number}
                isDisabled={disabled.filter((v) => v === "number").length !== 0}
              >
                <FormLabel>Number</FormLabel>
                <Input
                  id="number"
                  name="number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number}
                />
                <FormErrorMessage>{formik.errors.number}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="part_number"
                isInvalid={
                  formik.errors.part_number && formik.touched.part_number
                }
                isDisabled={
                  disabled.filter((v) => v === "part_number").length !== 0
                }
              >
                <FormLabel>Part Number</FormLabel>
                <Input
                  id="part_number"
                  name="part_number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.part_number}
                />
                <FormErrorMessage>{formik.errors.part_number}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="flow_rate"
                isInvalid={formik.errors.flow_rate && formik.touched.flow_rate}
                isDisabled={
                  disabled.filter((v) => v === "flow_rate").length !== 0
                }
              >
                <FormLabel>Flow Rate</FormLabel>
                <Input
                  id="flow_rate"
                  name="flow_rate"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.flow_rate}
                />
                <FormErrorMessage>{formik.errors.flow_rate}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="kind"
                isInvalid={formik.errors.kind && formik.touched.kind}
                isDisabled={disabled.filter((v) => v === "kind").length !== 0}
              >
                <FormLabel>Kind</FormLabel>
                <Select
                  placeholder="Select Type"
                  id="kind"
                  name="kind"
                  onChange={(e) =>
                    formik.setFieldValue("kind", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.kind}
                >
                  {KINDS.map((s, i) => (
                    <option value={i + 1} key={i}>
                      {s}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.kind}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="state"
                isInvalid={formik.errors.state && formik.touched.state}
                isDisabled={formik.values.kind !== 2}
              >
                <FormLabel>State</FormLabel>
                <Select
                  id="state"
                  name="state"
                  onChange={(e) =>
                    formik.setFieldValue("state", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <option disabled={true} value={0}>
                    Select a State
                  </option>
                  {STATES.map((s, i) => (
                    <option value={i + 1} key={i}>
                      {s}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="kit"
                isInvalid={formik.errors.kit && formik.touched.kit}
                isDisabled={
                  formik.values.kind !== 2 || formik.values.state !== 2
                }
              >
                <FormLabel>Kit</FormLabel>
                <Select
                  placeholder="Select Kit"
                  id="kit"
                  name="kit"
                  onChange={(e) =>
                    formik.setFieldValue("kit_id", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.kit_id}
                >
                  {kits
                    .filter((s) => s.id !== formik.values.frm)
                    .map((s) => (
                      <option value={s.id} key={s.id}>
                        Kit #{s.number}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>{formik.errors.kit}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function ConditionTubeElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="run_date"
                isInvalid={formik.errors.run_date && formik.touched.run_date}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Run Date</FormLabel>
                <Input
                  id="run_date"
                  name="run_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.run_date}
                />
                <FormErrorMessage>{formik.errors.run_date}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="date"
                isInvalid={formik.errors.date && formik.touched.date}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Date</FormLabel>
                <Input
                  id="date"
                  name="date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date}
                />
                <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="passed"
                isInvalid={formik.errors.passed && formik.touched.passed}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Passed</FormLabel>
                <Checkbox
                  id="passed"
                  name="passed"
                  type="checkbox"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passed}
                />
                <FormErrorMessage>{formik.errors.passed}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function DetailsElement(props: {
  tube: Tube | null;
  kits: Kit[] | null;
}): ReactElement {
  if (props.tube === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }
  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Conditioning Timeline</Tab>
        <Tab>History</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Center>
            <Table size="sm" variant="striped">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Serial Name</Td>
                </Tr>
                <Tr>
                  <Td>{props.tube.number}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Part Number</Td>
                </Tr>
                <Tr>
                  <Td>{props.tube.part_number || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Flow Rate</Td>
                </Tr>
                <Tr>
                  <Td>{props.tube.flow_rate || "Not defined"}</Td>
                </Tr>
                {props.tube.notes && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Notes</Td>
                    </Tr>
                    <Tr>
                      <Td maxW="xl" sx={{ textWrap: "balance" }}>
                        {props.tube.notes}
                      </Td>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">On</Th>
                  <Th fontWeight="bold">Passed</Th>
                  <Th fontWeight="bold">Run Date</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.tube.conditions.map((c, idx) => (
                  <Tr key={idx}>
                    <Td>{`${c.date}`} </Td>
                    <Td textColor={c.passed ? "green" : "red"}>
                      {c.passed ? "✓" : "✗"}{" "}
                    </Td>
                    <Td>{`${c.run_date}`} </Td>
                    <Td maxW="xl" sx={{ textWrap: "balance" }}>
                      {c.notes}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">On</Th>
                  <Th fontWeight="bold">Changes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.tube.history
                  .sort((a, b) => b.event_id - a.event_id)
                  .map((c, idx) => (
                    <Tr key={idx}>
                      <Td>{`${c.created_at}`} </Td>
                      <Td>
                        {[
                          (c.prev_kind != c.new_kind && (
                            <>
                              {`Kind: ${kindToString(c.prev_kind)} ➔ ${kindToString(c.new_kind)}`}
                              <br />
                            </>
                          )) ||
                            "",
                          (c.prev_state != c.new_state && (
                            <>
                              {`State: ${stateToString(c.prev_state)} ➔ ${stateToString(c.new_state)}`}
                              <br />
                            </>
                          )) ||
                            "",
                          (c.prev_kit_id != c.new_kit_id &&
                            `Kit ID: ${getKitNo(c.prev_kit_id, props.kits) || "None"} ➔ ${getKitNo(c.new_kit_id, props.kits) || "None"}`) ||
                            "",
                        ]}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

function getKitNo(id: number | null, kits: Kit[] | null): string | null {
  if (id == null) return null;
  if (kits == null || kits.length === 0) return null;

  console.log("Looking for", id, "in", kits);
  return kits.filter((v) => v.id === id)[0].number;
}

function kindToString(s: number | null): string {
  if (s == null) return "None";
  if (s == 1) return "Calibration";
  if (s == 2) return "Sampling";
  if (s == 3) return "Research";

  return "";
}

function stateToString(s: number | null): string {
  if (s == null) return "None";
  if (s == 1) return "In Fridge without Sample";
  if (s == 2) return "In Kit";
  if (s == 3) return "In Analysis";
  if (s == 4) return "In Fridge with Sample";
  if (s == 5) return "Others";

  return "";
}

function BulkConditioningElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled || [];
  const tubes: Tube[] = props.tubes || [];

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                isInvalid={formik.errors.tubes && formik.touched.tubes}
              >
                <FormLabel textAlign="center" onClick={() => {}}>
                  Tubes (Comma-separated)
                </FormLabel>
                <Input
                  id="tubes"
                  name="tubes"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tubes}
                />
                <FormErrorMessage>{formik.errors.tubes}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="run_date"
                isInvalid={formik.errors.run_date && formik.touched.run_date}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Run Date</FormLabel>
                <Input
                  id="run_date"
                  name="run_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.run_date}
                />
                <FormErrorMessage>{formik.errors.run_date}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="date"
                isInvalid={formik.errors.date && formik.touched.date}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Date</FormLabel>
                <Input
                  id="date"
                  name="date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date}
                />
                <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="passed"
                isInvalid={formik.errors.passed && formik.touched.passed}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Passed</FormLabel>
                <Checkbox
                  id="passed"
                  name="passed"
                  type="checkbox"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passed}
                />
                <FormErrorMessage>{formik.errors.passed}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function BulkStateElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled || [];
  const kits: Kit[] = props.kits || [];

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                isInvalid={formik.errors.tubes && formik.touched.tubes}
              >
                <FormLabel textAlign="center" onClick={() => {}}>
                  Tubes (Comma-separated)
                </FormLabel>
                <Input
                  id="tubes"
                  name="tubes"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tubes}
                />
                <FormErrorMessage>{formik.errors.tubes}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="kind"
                isInvalid={formik.errors.kind && formik.touched.kind}
                isDisabled={disabled.filter((v) => v === "kind").length !== 0}
              >
                <FormLabel>Kind</FormLabel>
                <Select
                  placeholder="Select Type"
                  id="kind"
                  name="kind"
                  onChange={(e) =>
                    formik.setFieldValue("kind", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.kind}
                >
                  {KINDS.map((s, i) => (
                    <option value={i + 1} key={i}>
                      {s}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.kind}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="state"
                isInvalid={formik.errors.state && formik.touched.state}
                isDisabled={formik.values.kind !== 2}
              >
                <FormLabel>State</FormLabel>
                <Select
                  id="state"
                  name="state"
                  onChange={(e) =>
                    formik.setFieldValue("state", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <option disabled={true} value={0}>
                    Select a State
                  </option>
                  {STATES.map((s, i) => (
                    <option value={i + 1} key={i}>
                      {s}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="kit"
                isInvalid={formik.errors.kit && formik.touched.kit}
                isDisabled={
                  formik.values.kind !== 2 || formik.values.state !== 2
                }
              >
                <FormLabel>Kit</FormLabel>
                <Select
                  placeholder="Select Kit"
                  id="kit"
                  name="kit"
                  onChange={(e) =>
                    formik.setFieldValue("kit_id", parseInt(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.kit_id}
                >
                  {kits
                    .filter((s) => s.id !== formik.values.frm)
                    .map((s) => (
                      <option value={s.id} key={s.id}>
                        Kit #{s.number}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage>{formik.errors.kit}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}
