import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { FiEdit2, FiSearch, FiTrash2 } from "react-icons/fi";
import { MdFindInPage, MdLocalShipping } from "react-icons/md";
import { FaClockRotateLeft } from "react-icons/fa6";
import { VscError } from "react-icons/vsc";
import { BiDetail } from "react-icons/bi";
import {
  add_pump,
  bulk_send,
  calibrate_pump,
  delete_pump,
  edit_pump,
  get_kits,
  get_pump,
  get_pumps,
  get_sites,
  get_tubes,
  import_csv,
  mark_lost_pump,
  mark_received_pump,
  mark_returned_pump,
  send_pump,
} from "../../common/Api";
import {
  AddPumpSchema,
  BulkShipSchema,
  EditPumpSchema,
  Kit,
  Pump,
  ShipSchema,
  Site,
  Tube,
  validateCSV,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import { useSearchParams } from "react-router-dom";
import { download_csv_sample, LoadingSpinner } from "../../common/Components";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Papa from "papaparse";
import { BulkShipmentElement } from "./kits";
import { getPermissionList } from "../../token";

export const TODAY = moment().format("yyyy-MM-DD");

enum ModalModes {
  ADD,
  CALIBRATE,
  DETAILS,
  EDIT,
  SEND_RECEIVE,
  MARK_RECEIVED,
  BULK_SHIPMENT,
}

export function PumpManagement() {
  const toast = useToast();
  const permissions = getPermissionList();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(0);
  const [pump, setPump] = useState(null as Pump | null);

  const [pumps, setPumps] = useState(new Array<Pump>());
  const [sites, setSites] = useState(Array<Site>());
  const [tubes, setTubes] = useState(null as Array<Tube> | null);

  const [allKits, setAllKits] = useState(null as Kit[] | null);
  const [allPumps, setAllPumps] = useState(null as Pump[] | null);

  const [queryBuffer, setQueryBuffer] = useState(query as string);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        return params;
      });
      setPump(null);
    },
  });

  const createForm = useFormik({
    initialValues: {
      name: "",
      number: "",
      charger: "",
      notes: "",
      site: 0,
    },
    validationSchema: AddPumpSchema,
    onSubmit: async (values) => {
      toast.promise(
        add_pump(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
          createForm.resetForm();
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      name: "",
      number: "",
      charger: "",
      notes: "",
    },
    validationSchema: EditPumpSchema,
    onSubmit: async (values) => {
      toast.promise(
        edit_pump(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  const shipForm = useFormik({
    initialValues: {
      frm: 0,
      to: 0,
      sent: TODAY,
      received: null,
      tracking_number: "",
      notes: "",
    },
    validationSchema: ShipSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.received === "") body.received = null;
      toast.promise(
        send_pump(id as number, body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  const receivedForm = useFormik({
    initialValues: {
      received: TODAY,
      extra: false,
    },
    onSubmit: async (values) => {
      if (pump === null) return;
      const { extra, ...body } = values;
      const fn = !extra ? mark_received_pump : mark_returned_pump;
      toast.promise(
        fn(pump.id, body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  const calibrationForm = useFormik({
    initialValues: {
      reading_before: 0,
      reading_after: 0,
      target_reading: 0,
      tube: 0,
      date: TODAY,
      notes: "",
    },
    onSubmit: async (values) => {
      toast.promise(
        calibrate_pump(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  const bulkShipForm = useFormik({
    initialValues: {
      assets: [],
      frm: 0,
      to: 0,
      sent: TODAY,
      received: "" as string | null,
      tracking_number: "",
      notes: "",
    },
    validationSchema: BulkShipSchema,
    onSubmit: async (values) => {
      const body = values;
      if (body.received === "") body.received = null;
      toast.promise(
        bulk_send(body).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Saved" },
          error: {
            title: "Failed to Save",
            description: "Please contact support",
          },
          loading: { title: "Saving..." },
        },
      );
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.ADD:
        break;
      case ModalModes.EDIT:
        get_pump(id as number)
          .then((res) => {
            const pump = res.data as Pump;
            setPump(pump);
            editForm.setValues({
              name: pump.name || "",
              number: pump.number || "",
              charger: pump.charger || "",
              notes: pump.notes || "",
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.DETAILS:
        get_pump(id as number)
          .then((res) => {
            const pump = res.data as Pump;
            setPump(pump);
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case ModalModes.SEND_RECEIVE:
        get_pump(id as number)
          .then((res) => {
            const pump = res.data as Pump;
            setPump(pump);
            shipForm.setValues({
              frm: pump.locations[0].to,
              to: 0,
              sent: TODAY,
              received: null,
              tracking_number: "",
              notes: "",
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.MARK_RECEIVED:
        get_pump(id as number)
          .then((res) => {
            const pump = res.data as Pump;
            setPump(pump);
            receivedForm.setValues({
              received: TODAY,
              extra: false,
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.CALIBRATE:
        calibrationForm.setValues({
          reading_before: 0,
          reading_after: 0,
          target_reading: 0,
          tube: 0,
          date: TODAY,
          notes: "",
        });
        break;
      case ModalModes.BULK_SHIPMENT:
        break;
      default:
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_pumps(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.pumps as Array<Pump>;
        const t = res.data.total;
        setPumps(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  useEffect(() => {
    get_sites(1, 0, "", "").then((res) => {
      const d = res.data.sites as Array<Site>;
      setSites(d);
    });

    get_tubes(1, 0, "", "").then((res) => {
      const d = (res.data.tubes as Array<Tube>).filter((v) => v.kind === 1);
      setTubes(d);
    });
  }, []);

  useEffect(() => {
    get_kits(1, 0, "", "").then((res) => {
      const d = res.data.kits as Array<Kit>;
      setAllKits(d);
    });
  }, []);

  useEffect(() => {
    get_pumps(1, 0, "", "").then((res) => {
      const d = res.data.pumps as Array<Pump>;
      setAllPumps(d);
    });
  }, []);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack direction={{ base: "column", md: "row" }} justify="end">
              {(permissions[4].verb & 1) != 0 && (
                <InputGroup maxW="xs">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return;

                      setSearchParams((params) => {
                        params.set("query", queryBuffer);
                        params.set("page", "1");
                        return params;
                      });
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setQueryBuffer(e.target.value);
                    }}
                    value={queryBuffer}
                  />
                </InputGroup>
              )}

              {(permissions[4].verb & 2) != 0 && (
                <>
                  <FormControl
                    colorScheme="blue"
                    justifyContent="end"
                    display="flex"
                  >
                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-l-lg text-sm font-bold px-2.5 py-2.5 text-center"
                      title="Download a Sample CSV"
                      onClick={()=>{download_csv_sample("pumps")}}
                    >
                      i
                    </button>
                    <FormLabel
                      htmlFor="file_button"
                      textColor="white"
                      backgroundColor="#5728b5"
                      cursor="pointer"
                      py={2.5}
                      px={4}
                      rounded={5}
                      roundedLeft={0}
                      m={0}
                      w="max-content"
                      fontWeight="bold"
                      _hover={{
                        backgroundColor: "#6530cf",
                      }}
                    >
                      Import CSV
                    </FormLabel>
                    <Input
                      id="file_button"
                      name="file"
                      type="file"
                      display="none"
                      accept=".csv"
                      onChange={(e) => {
                        const files = e.currentTarget.files;
                        if (!files) return;
                        const file = files[0];
                        const reader = new FileReader();
                        reader.onload = ({ target }) => {
                          if (!target) return;
                          const columns = [
                            "site",
                            "number",
                            "charger",
                            "notes",
                          ];
                          const csv = Papa.parse<any>(target.result as string, {
                            header: true,
                          });
                          const errors = validateCSV(csv, columns);
                          if (errors.length !== 0) {
                            toast({
                              title: "Errors loading csv file",
                              description: errors.join(" | "),
                              status: "error",
                            });
                            return;
                          }

                          const tid = toast({
                            title: "Importing ...",
                            status: "loading",
                            duration: null,
                          });
                          import_csv({
                            resource: "pumps",
                            data: target.result as string,
                          })
                            .then(() => {
                              toast.update(tid, {
                                status: "success",
                                title: "Finished importing successfully",
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            })
                            .catch((err) => {
                              toast.update(tid, {
                                status: "error",
                                title: "Failed to import",
                                description: err.response.data.join(" | "),
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            });
                        };

                        reader.readAsText(file);
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.BULK_SHIPMENT.toString());
                        return params;
                      });
                    }}
                    id="bulk_shipment"
                  >
                    Bulk Shipment
                  </Button>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.ADD.toString());
                        return params;
                      });
                    }}
                    id="add"
                  >
                    Add Pump
                  </Button>
                </>
              )}
            </Stack>
          </Box>
          {(permissions[4].verb & 1) != 0 && (
            <>
              <Box overflowX="auto">
                <MyTable<Pump>
                  sortColumn={sort as string}
                  setSearchParams={setSearchParams}
                  rowAction={(p) => {
                    setSearchParams((params) => {
                      params.set("id", p.id.toString());
                      params.set("mode", ModalModes.DETAILS.toString());
                      return params;
                    });
                  }}
                  columns={[
                    { name: "Pump Name", width: "15%", sort_id: "name" },
                    { name: "Pump Serial No", width: "20%", sort_id: "number" },
                    { name: "Current Location", width: "20%" },
                    {
                      name: "Last Calibration",
                      width: "25%",
                      sort_id: "last_calibration",
                    },
                    { name: "Actions", width: "20%" },
                  ]}
                  data={pumps}
                  bindings={[
                    (r: Pump) => r.name,
                    (r: Pump) => r.number,
                    (r: Pump) => {
                      if (r.lost) return <Badge colorScheme="red">Lost</Badge>;
                      return (
                        <>
                          {!r.locations[0].date_received && (
                            <Badge colorScheme="green" mr={2}>
                              In-Transit {"->"}
                            </Badge>
                          )}
                          <Link
                            href={`/sites?id=${r.locations[0].id}&mode=2`}
                            isExternal
                          >
                            {`${r.locations[0].name}`}{" "}
                            <ExternalLinkIcon mx="2px" />
                          </Link>
                        </>
                      );
                    },
                    (r) =>
                      r.calibrations.length === 0
                        ? "Never"
                        : r.calibrations[0].date,
                  ]}
                  rk={(r: Pump) => `${r.id}`}
                  functions={[
                    {
                      title: "Edit",
                      icon: <FiEdit2 />,
                      condition: () => (permissions[4].verb & 2) != 0,
                      fn: (p: Pump) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.EDIT.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Mark as Lost",
                      icon: <VscError />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        if (
                          !window.confirm(
                            "Are you sure you want to mark this pump as lost?",
                          )
                        )
                          return;

                        mark_lost_pump(p.id).then(() =>
                          window.location.reload(),
                        );
                      },
                    },
                    {
                      title: "Mark as Found",
                      icon: <MdFindInPage />,
                      condition: (r) =>
                        r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        if (
                          !window.confirm(
                            "Are you sure you want to mark this pump as found?",
                          )
                        )
                          return;

                        mark_lost_pump(p.id).then(() =>
                          window.location.reload(),
                        );
                      },
                    },
                    {
                      title: "Send/Receive",
                      icon: <MdLocalShipping />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          if (p.locations[0].date_received === null) {
                            params.set(
                              "mode",
                              ModalModes.MARK_RECEIVED.toString(),
                            );
                          } else {
                            params.set(
                              "mode",
                              ModalModes.SEND_RECEIVE.toString(),
                            );
                          }
                          return params;
                        });
                      },
                    },
                    {
                      title: "Calibrate",
                      icon: <FaClockRotateLeft />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 2) != 0,
                      fn: (p) => {
                        if (p.lost) {
                          window.alert("You cannot calibrate a lost pump");
                          return;
                        }

                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.CALIBRATE.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Detailed View",
                      icon: <BiDetail />,
                      fn: (p) => {
                        setSearchParams((params) => {
                          params.set("id", p.id.toString());
                          params.set("mode", ModalModes.DETAILS.toString());
                          return params;
                        });
                      },
                    },
                    {
                      title: "Delete",
                      icon: <FiTrash2 color="darkred" />,
                      condition: (r) =>
                        !r.lost && (permissions[4].verb & 4) != 0,
                      fn: (p) => {
                        const yes = window.confirm(
                          `Are you sure you want to delete the pump "${p.number}"? This action cannot be undone`,
                        );
                        if (!yes) return;
                        toast.promise(
                          delete_pump(p.id).then(() => {
                            setSearchParams((params) => {
                              params.set("mode", "-1");
                              return params;
                            });
                            window.location.reload();
                          }),
                          {
                            success: { title: "Successfully Deleted" },
                            error: {
                              title: "Failed to Delete Pump",
                              description: "Please contact support",
                            },
                            loading: { title: "Deleting..." },
                          },
                        );
                      },
                    },
                  ]}
                />
              </Box>
              <Box bg="bg.surface">
                <Container>
                  <Pagination
                    count={totalCount}
                    pageSize={pageSize as number}
                    siblingCount={2}
                    page={page as number}
                    onChange={(e: any) => {
                      setSearchParams((params) => {
                        params.set("page", e.page);
                        return params;
                      });
                    }}
                  />
                </Container>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                  onChange={(e) => {
                    setSearchParams((params) => {
                      params.set("pageSize", e.target.value);
                      return params;
                    });
                  }}
                  value={pageSize}
                >
                  <option value="10">10 Results per Page</option>
                  <option value="20">20 Results per Page</option>
                  <option value="30">30 Results per Page</option>
                  <option value="50">50 Results per Page</option>
                  <option value="100">100 Results per Page</option>
                </select>
              </Box>
            </>
          )}
        </Stack>
      </Box>
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          isSubmitting={createForm.isSubmitting}
          body={
            <AddPumpElement formik={createForm} disabled={[]} sites={sites} />
          }
          title={"Add Pump"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          isSubmitting={editForm.isSubmitting}
          body={<AddPumpElement formik={editForm} disabled={[]} edit={true} />}
          title={"Edit Pump"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.CALIBRATE && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={calibrationForm.handleSubmit}
          isSubmitting={calibrationForm.isSubmitting}
          body={<CalibrationElement formik={calibrationForm} tubes={tubes} />}
          title={"Calibrate Pump"}
          buttonText="Save"
        />
      )}
      {(permissions[4].verb & 2) != 0 &&
        mode === ModalModes.SEND_RECEIVE &&
        sites.length > 0 && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={shipForm.handleSubmit}
            isSubmitting={shipForm.isSubmitting}
            body={<ShipPumpElement formik={shipForm} sites={sites} />}
            title={"Send/Receive Pump"}
            buttonText="Save"
          />
        )}
      {(permissions[4].verb & 2) != 0 && mode === ModalModes.MARK_RECEIVED && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={receivedForm.handleSubmit}
          isSubmitting={receivedForm.isSubmitting}
          body={<ArrivedElement formik={receivedForm} asset={pump} />}
          title={"Mark Pump as Received"}
          buttonText="Save as Received"
          extraButtonText="Save as Returned"
          setFieldValue={receivedForm.setFieldValue}
        />
      )}
      {(permissions[4].verb & 1) != 0 && mode === ModalModes.DETAILS && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={<DetailsElement pump={pump} />}
          title={"Pump Details"}
        />
      )}
      {(permissions[4].verb & 2) != 0 &&
        mode === ModalModes.BULK_SHIPMENT &&
        sites.length > 0 && (
          <GeneralModal
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleSubmit={bulkShipForm.handleSubmit}
            body={
              <BulkShipmentElement
                formik={bulkShipForm}
                kits={allKits}
                pumps={allPumps}
                sites={sites}
              />
            }
            title={"Bulk Shipment"}
            buttonText="Save"
          />
        )}
    </Container>
  );
}

function ShipPumpElement(props: any): ReactElement {
  const formik = props.formik;
  const sites: Array<Site> | null = props.sites;

  if (sites === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl>
                <FormLabel textAlign="center">From</FormLabel>
                <Input
                  isDisabled={true}
                  value={
                    formik.values.frm &&
                    sites.filter((s) => s.id === formik.values.frm)[0].name
                  }
                />
              </FormControl>
              {sites.length === 0 || (
                <FormControl
                  id="site"
                  isInvalid={formik.errors.site && formik.touched.site}
                >
                  <FormLabel textAlign="center">To</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="site"
                    name="site"
                    onChange={(e) =>
                      formik.setFieldValue("to", parseInt(e.target.value))
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.site}
                  >
                    {sites
                      .filter((s) => s.id !== formik.values.frm)
                      .map((s) => (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.site}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="sent">
                <FormLabel textAlign="center">Sent On</FormLabel>
                <Input
                  name="sent"
                  id="sent"
                  size="md"
                  type="date"
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.sent}
                />
                <FormErrorMessage>{formik.errors.sent}</FormErrorMessage>
              </FormControl>
              <FormControl id="received">
                <FormLabel textAlign="center">Received On</FormLabel>
                <Input
                  name="received"
                  id="received"
                  size="md"
                  type="date"
                  min={formik.values.sent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received}
                />
                <FormErrorMessage>{formik.errors.received}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="tracking_number"
                isInvalid={
                  formik.errors.tracking_number &&
                  formik.touched.tracking_number
                }
              >
                <FormLabel>Tracking Number</FormLabel>
                <Input
                  id="tracking_number"
                  name="tracking_number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tracking_number}
                />
                <FormErrorMessage>
                  {formik.errors.tracking_number}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

export function ArrivedElement(props: any): ReactElement {
  const formik = props.formik;
  const asset: Kit | Pump = props.asset;

  if (asset === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="received">
                <FormLabel textAlign="center" fontSize="xl">
                  The{" "}
                  {Object.keys(asset).filter((v) => v === "bag_conditioned")
                    ? "kit"
                    : "pump"}{" "}
                  is currently in-transit.
                </FormLabel>
                <FormLabel textAlign="center" fontSize="xl">
                  When did it arrive at{" "}
                  <strong>{asset.locations[0].name}</strong>?
                </FormLabel>
                <Input
                  name="received"
                  id="received"
                  size="md"
                  type="date"
                  min={asset.locations[0].date_sent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received}
                />
                <FormErrorMessage>{formik.errors.received}</FormErrorMessage>
                {asset.locations[0].bulk_id && (
                  <FormLabel
                    textAlign="center"
                    fontSize="md"
                    textColor="red"
                    mt={2}
                  >
                    Note: This is a bulk shipment, all of the items within this
                    shipment will be marked as received/returned.
                  </FormLabel>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function AddPumpElement(props: {
  formik: any;
  disabled: string[];
  sites?: Site[];
  edit?: boolean;
}): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;
  const sites: Array<Site> = props.sites || [];

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="name"
                isInvalid={formik.errors.name && formik.touched.name}
                isDisabled={disabled.filter((v) => v === "name").length !== 0}
              >
                <FormLabel>Pump Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="number"
                isInvalid={formik.errors.number && formik.touched.number}
                isDisabled={disabled.filter((v) => v === "number").length !== 0}
              >
                <FormLabel>Serial Number</FormLabel>
                <Input
                  id="number"
                  name="number"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number}
                />
                <FormErrorMessage>{formik.errors.number}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="charger"
                isInvalid={formik.errors.charger && formik.touched.charger}
                isDisabled={
                  disabled.filter((v) => v === "charger").length !== 0
                }
              >
                <FormLabel>Pump Charger</FormLabel>
                <Input
                  id="charger"
                  name="charger"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.charger}
                />
                <FormErrorMessage>{formik.errors.charger}</FormErrorMessage>
              </FormControl>
              {props.edit || (
                <FormControl
                  id="site"
                  isInvalid={formik.errors.site && formik.touched.site}
                  isDisabled={disabled.filter((v) => v === "name").length !== 0}
                >
                  <FormLabel>Current Location</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="site"
                    name="site"
                    onChange={(e) => {
                      formik.setFieldValue("site", parseInt(e.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.site}
                  >
                    {sites.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.site}</FormErrorMessage>
                </FormControl>
              )}
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function CalibrationElement(props: any): ReactElement {
  const formik = props.formik;
  const tubes: Array<Tube> | null = props.tubes;

  if (tubes === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="reading_before">
                <FormLabel textAlign="center">Reading Before</FormLabel>
                <Input
                  name="reading_before"
                  id="reading_before"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reading_before}
                  type="number"
                />
              </FormControl>
              <FormControl id="reading_after">
                <FormLabel textAlign="center">Reading After</FormLabel>
                <Input
                  name="reading_after"
                  id="reading_after"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reading_after}
                  type="number"
                />
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl id="target_reading">
                <FormLabel textAlign="center">Target Reading</FormLabel>
                <Input
                  name="target_reading"
                  id="target_reading"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.target_reading}
                  type="number"
                />
              </FormControl>
              <FormControl id="date">
                <FormLabel textAlign="center">Calibration Date</FormLabel>
                <Input
                  name="date"
                  id="date"
                  size="md"
                  type="date"
                  max={TODAY}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date}
                />
                <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
              </FormControl>
            </Stack>
            {
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={{ base: "5", lg: "8" }}
                justify="space-between"
              >
                <FormControl
                  id="tube"
                  isInvalid={formik.errors.tube && formik.touched.tube}
                >
                  <FormLabel>Calibration Tube</FormLabel>
                  <Select
                    placeholder="Select option"
                    id="tube"
                    name="tube"
                    onChange={(e) => {
                      formik.setFieldValue("tube", parseInt(e.target.value));
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tube}
                  >
                    {tubes.map((s) => (
                      <option value={s.id} key={s.id}>
                        {s.number} {s.part_number && `(${s.part_number})`}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formik.errors.tube}</FormErrorMessage>
                </FormControl>
              </Stack>
            }
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function DetailsElement(props: { pump: Pump | null }): ReactElement {
  if (props.pump === null)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Calibration History</Tab>
        <Tab>Location History</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Center>
            <Table size="sm" variant="striped">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Serial Number</Td>
                </Tr>
                <Tr>
                  <Td>{props.pump.number || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Charger</Td>
                </Tr>
                <Tr>
                  <Td>{props.pump.charger || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Lost</Td>
                </Tr>
                <Tr>
                  <Td>{props.pump.lost ? "Yes" : "No"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Notes</Td>
                </Tr>
                <Tr>
                  <Td maxW="xl" sx={{ "text-wrap": "balance" }}>
                    {props.pump.notes || "None"}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">On</Th>
                  <Th fontWeight="bold">Readings</Th>
                  <Th fontWeight="bold">Tube</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.pump.calibrations.map((c) => (
                  <Tr key={c.id}>
                    <Td>{`${c.date}`} </Td>
                    <Td>
                      {`${c.reading_before} -> ${c.reading_after} [Target: ${c.target_reading}]`}
                    </Td>
                    <Td>
                      <Link href={`/tubes?id=${c.tube_id}&mode=2`} isExternal>
                        {`${c.number}`} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td maxW="xl" sx={{ "text-wrap": "balance" }}>
                      {c.notes}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Sent On</Th>
                  <Th fontWeight="bold">Received On</Th>
                  <Th fontWeight="bold">To</Th>
                  <Th fontWeight="bold">Tracking Number</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.pump.locations.map((p) => (
                  <Tr key={p.id}>
                    <Td>{`${p.date_sent}`} </Td>
                    <Td>{`${p.date_received || "In-Transit"}`} </Td>
                    <Td>
                      <Link href={`/sites?id=${p.to}&mode=2`} isExternal>
                        {`${p.name}`} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td> {p.tracking_number || "None"} </Td>
                    <Td maxW="xl" sx={{ "text-wrap": "balance" }}>
                      {" "}
                      {`${p.notes}`}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
