import {
  Box,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiMoreVertical } from "react-icons/fi";
import { FaKitMedical, FaPumpMedical, FaUsers } from "react-icons/fa6";
import { GoProjectTemplate } from "react-icons/go";
import { RiMentalHealthFill } from "react-icons/ri";
import { GiCorkedTube } from "react-icons/gi";
import { logout, refresh, REFRESH_PATH, user_info } from "../../common/Api";
import { SidebarButton } from "./SidebarButton";
import { FaDatabase } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getPermissionList } from "../../token";
import { AiOutlineAudit } from "react-icons/ai";

const demo = process.env.REACT_APP_DEMO === "1";

class User {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phone: string = "";
}

const urls = {
  Users: [{ title: "Users", url: "/users", icon: <FaUsers />, resource: 0 }],
  Patients: [
    {
      title: "Patients",
      url: "/patients",
      icon: <RiMentalHealthFill />,
      resource: 1,
    },
    { title: "Raw Data", url: "/data", icon: <FaDatabase />, resource: 2 },
  ],
  Projects: [
    {
      title: "Projects",
      url: "/projects",
      icon: <GoProjectTemplate />,
      resource: 3,
    },
    { title: "Sites", url: "/sites", icon: <FaUsers />, resource: 3 },
  ],
  Assets: [
    { title: "Pumps", url: "/pumps", icon: <FaPumpMedical />, resource: 4 },
    { title: "Kits", url: "/kits", icon: <FaKitMedical />, resource: 4 },
    { title: "Tubes", url: "/tubes", icon: <GiCorkedTube />, resource: 4 },
  ],
  Audit: [
    { title: "Logs", url: "/logs", icon: <AiOutlineAudit />, resource: 5 },
  ],
};

axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (config) => config,
  async function (error) {
    if (
      error.response.status === 403 &&
      !error.config.url.endsWith(REFRESH_PATH) &&
      !error.config.__isRetry
    ) {
      const res = await refresh();

      if (res.status === 200) {
        error.config.__isRetry = true;
        return axios(error.config);
      }
    }

    return Promise.reject(error);
  },
);

function logOut() {
  logout().finally(() => window.location.reload());
}

export const HomePage: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [user, setUser] = useState<User>(new User());

  const permissions = getPermissionList();

  useMemo(() => {
    if (process.env.REACT_APP_DEMO === "1") return;

    user_info().then(({ data }) => {
      setUser({
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname,
        phone: data.phone,
      });
    });
  }, []);

  useEffect(() => {
    if (demo) return;
    const t = setTimeout(() => refresh(), 10_000 * 60);

    return () => {
      clearTimeout(t);
    };
  }, []);

  const loc = useLocation();
  const currentPage = loc.pathname;

  return (
    <Flex as="section" minH="100vh">
      <Stack
        flex="1"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.accent.default"
        color="fg.accent.default"
        borderRightWidth="1px"
        justifyContent="space-between"
        margin={3}
        rounded={5}
      >
        <Stack spacing="8">
          <Heading size="xs" py={2} letterSpacing={-0.5} textAlign="center">
            SHIRLEY DIAGNOSTICS MANAGEMENT SYSTEM
          </Heading>
          <Stack spacing="1">
            {Object.entries(urls).map(([k, v]) => (
              <div key={k}>
                { (demo || v.filter((u) => permissions[u.resource].verb > 0).length > 0) &&
                  <Text
                    px="3"
                    fontSize="xs"
                    fontWeight="semibold"
                    textTransform="uppercase"
                    letterSpacing="widest"
                    mt="3"
                  >
                    {k}
                  </Text>
                }
                <Stack direction="column" spacing={0.1}>
                  {v.map(
                    (u) =>
                      (demo || permissions[u.resource].verb > 0) && (
                        <SidebarButton
                          key={u.url}
                          id={u.url.slice(1)}
                          leftIcon={u.icon}
                          onClick={() => (window.location.href = u.url)}
                          fontWeight="medium"
                          textColor={currentPage === u.url ? "lightgreen" : ""}
                        >
                          {u.title}
                        </SidebarButton>
                      ),
                  )}
                </Stack>
              </div>
            ))}
          </Stack>
        </Stack>
        <Stack
          spacing="4"
          divider={<StackDivider borderColor="bg.accent.subtle" />}
        >
          <Box />
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Box>
                <Text textStyle="sm" fontWeight="medium">
                  {user.firstName} {user.lastName}
                </Text>
                <Text textStyle="sm" color="fg.accent.muted">
                  {user.email}
                </Text>
              </Box>
            </HStack>
            <Menu>
              <MenuButton>
                <FiMoreVertical />
              </MenuButton>
              <MenuList textColor="bg.accent.default">
                <MenuItem
                  onClick={() => (window.location.href = "/settings")}
                  fontWeight="bold"
                  textStyle="bold"
                >
                  Settings
                </MenuItem>
                <MenuItem
                  onClick={() => logOut()}
                  fontWeight="bold"
                  textStyle="bold"
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
      </Stack>
      {children}
    </Flex>
  );
};
