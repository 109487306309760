import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Heading,
  Link,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { FiEdit2, FiSearch } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import {
  add_project,
  edit_project,
  get_file_link,
  get_project,
  get_projects,
} from "../../common/Api";
import { LoadingSpinner } from "../../common/Components";
import {
  AddProjectSchema,
  EditProjectSchema,
  Project,
  Question,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { TODAY } from "./pumps";
import { BiDetail } from "react-icons/bi";
import { getPermissionList } from "../../token";
import { FaDownload } from "react-icons/fa6";

enum ModalModes {
  ADD,
  EDIT,
  SHOW_DETAILS,
}

export function ProjectManagement() {
  const toast = useToast();

  const permissions = getPermissionList();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(1);
  const [projects, setProjects] = useState(new Array<Project>());

  const [project, setProject] = useState(null as Project | null);
  const [queryBuffer, setQueryBuffer] = useState(query as string);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        return params;
      });
      setProject(null);
    },
  });

  const createForm = useFormik({
    initialValues: {
      title: "",
      expected_recruitment: 0,
      start_date: TODAY,
      end_date: TODAY,
      survey: [] as Question[],
      notes: "",
    },
    validationSchema: AddProjectSchema,
    onSubmit: async (values) => {
      toast.promise(
        add_project(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
          createForm.resetForm();
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      title: "",
      expected_recruitment: 0,
      start_date: TODAY,
      end_date: TODAY,
      survey: [] as Question[],
      notes: "",
    },
    validationSchema: EditProjectSchema,
    onSubmit: async (values) => {
      toast.promise(
        edit_project(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.EDIT:
        get_project(id as number)
          .then((res) => {
            const project = res.data as Project;
            setProject(project);
            editForm.setValues({
              title: project.title || "",
              expected_recruitment: project.expected_recruitment || 0,
              start_date: project.start_date || TODAY,
              end_date: project.end_date || TODAY,
              notes: project.notes || "",
              survey: project.survey || [],
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.SHOW_DETAILS:
        get_project(id as number)
          .then((res) => setProject(res.data))
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case -1:
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_projects(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.projects as Array<Project>;
        const t = res.data.total;
        setProjects(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              {(permissions[3].verb & 1) != 0 && (
                <InputGroup maxW="xs">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return;

                      setSearchParams((params) => {
                        params.set("query", queryBuffer);
                        params.set("page", "1");
                        return params;
                      });
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setQueryBuffer(e.target.value);
                    }}
                    value={queryBuffer}
                  />
                </InputGroup>
              )}
              {(permissions[3].verb & 2) != 0 && (
                <Button
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set("id", "-1");
                      params.set("mode", ModalModes.ADD.toString());
                      return params;
                    });
                  }}
                  id="add"
                >
                  Add Project
                </Button>
              )}
            </Stack>
          </Box>
          {(permissions[3].verb & 1) != 0 && (
            <Box overflowX="auto">
              <MyTable<Project>
                sortColumn={sort as string}
                setSearchParams={setSearchParams}
                rowAction={(p) => {
                  setSearchParams((params) => {
                    params.set("id", p.id.toString());
                    params.set("mode", ModalModes.SHOW_DETAILS.toString());
                    return params;
                  });
                }}
                columns={[
                  { name: "Title", width: "25%", sort_id: "title" },
                  {
                    name: "Expected Recruitment",
                    width: "15%",
                    sort_id: "expected_recruitment",
                  },
                  {
                    name: "Actual Recruitment",
                    width: "15%",
                    sort_id: "actual_recruitment",
                  },
                  { name: "Start Date", width: "20%", sort_id: "start_date" },
                  { name: "End Date", width: "20%", sort_id: "end_date" },
                  { name: "Actions", width: "10%" },
                ]}
                data={projects}
                bindings={[
                  (r: Project) => r.title,
                  (r: Project) => r.expected_recruitment,
                  (r: Project) => r.actual_recruitment,
                  (r: Project) => r.start_date,
                  (r: Project) => r.end_date,
                ]}
                rk={(r: Project) => `${r.id}`}
                functions={[
                  {
                    title: "Edit",
                    icon: <FiEdit2 />,
                    fn: (p: Project) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.EDIT.toString());
                        return params;
                      });
                    },
                    condition: () => (permissions[3].verb & 2) != 0,
                  },
                  {
                    title: "Show Activity & Assets",
                    icon: <BiDetail />,
                    fn: (p) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.SHOW_DETAILS.toString());
                        return params;
                      });
                    },
                  },
                ]}
              />
            </Box>
          )}
          {(permissions[3].verb & 1) != 0 && (
            <Box bg="bg.surface">
              <Container>
                <Pagination
                  count={totalCount}
                  pageSize={pageSize as number}
                  siblingCount={2}
                  page={page as number}
                  onChange={(e: any) => {
                    setSearchParams((params) => {
                      params.set("page", e.page);
                      return params;
                    });
                  }}
                />
              </Container>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                onChange={(e) => {
                  setSearchParams((params) => {
                    params.set("pageSize", e.target.value);
                    return params;
                  });
                }}
                value={pageSize}
              >
                <option value="10">10 Results per Page</option>
                <option value="20">20 Results per Page</option>
                <option value="30">30 Results per Page</option>
                <option value="50">50 Results per Page</option>
                <option value="100">100 Results per Page</option>
              </select>
            </Box>
          )}
        </Stack>
      </Box>
      {(permissions[3].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          body={
            <AddProjectElement
              formik={createForm}
              isEdit={false}
              disabled={[]}
            />
          }
          title={"Add Project"}
          buttonText="Save"
        />
      )}
      {(permissions[3].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={
            <AddProjectElement formik={editForm} isEdit={true} disabled={[]} />
          }
          title={"Edit Project"}
          buttonText="Save"
        />
      )}
      {(permissions[3].verb & 1) != 0 && mode === ModalModes.SHOW_DETAILS && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={<ShowDetailsElement project={project} />}
          title={"Activity & Assets"}
        />
      )}
    </Container>
  );
}

function AddProjectElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;
  const survey: Question[] = formik.values.survey;
  const isEdit: boolean = props.isEdit;

  console.log(survey);

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="title"
                isInvalid={formik.errors.title && formik.touched.title}
                isDisabled={disabled.filter((v) => v === "title").length !== 0}
              >
                <FormLabel>Title</FormLabel>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="expected_recruitment"
                isInvalid={
                  formik.errors.expected_recruitment &&
                  formik.touched.expected_recruitment
                }
                isDisabled={
                  disabled.filter((v) => v === "expected_recruitment")
                    .length !== 0
                }
              >
                <FormLabel>Expected Recruitment</FormLabel>
                <Input
                  id="expected_recruitment"
                  name="expected_recruitment"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expected_recruitment}
                />
                <FormErrorMessage>
                  {formik.errors.expected_recruitment}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="start_date"
                isInvalid={
                  formik.errors.start_date && formik.touched.start_date
                }
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>Start Date</FormLabel>
                <Input
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date}
                />
                <FormErrorMessage>{formik.errors.start_date}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="end_date"
                isInvalid={formik.errors.end_date && formik.touched.end_date}
                isDisabled={disabled.filter((v) => v === "date").length !== 0}
              >
                <FormLabel>End Date</FormLabel>
                <Input
                  id="end_date"
                  name="end_date"
                  type="date"
                  min={formik.values.start_date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date}
                />
                <FormErrorMessage>{formik.errors.end_date}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Divider />
            <Center>
              <Stack
                direction="column"
                spacing={{ base: "5", lg: "8" }}
                justify="space-between"
                textAlign="center"
              >
                <Heading size="xs">Survey</Heading>
                {survey.map((v, i) => {
                  return (
                    <div key={i} className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`question-${i}`}
                          placeholder="Question Title..."
                          value={v.question}
                          disabled={isEdit && v.id != null}
                          onChange={(e) => {
                            formik.setFieldValue("survey", [
                              ...survey.slice(0, i),
                              {
                                question: e.target.value,
                                kind: v.kind,
                                options: v.options,
                              },
                              ...survey.slice(i + 1),
                            ]);
                          }}
                        ></input>
                        <div className="text-red-700">
                          {(formik.errors.survey &&
                            formik.errors.survey[i]?.question) ||
                            ""}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id={`question_kind-${i}`}
                            value={v.kind}
                            disabled={isEdit && v.id != null}
                            onChange={(e) => {
                              formik.setFieldValue("survey", [
                                ...survey.slice(0, i),
                                {
                                  question: v.question,
                                  kind: parseInt(e.target.value),
                                  options: v.options,
                                },
                                ...survey.slice(i + 1),
                              ]);
                            }}
                          >
                            <option value="0">Question Kind...</option>
                            <option value="1">
                              Multiple Choice (One Answer)
                            </option>
                            {
                              // <option value="2">
                              //   Multiple Choice (Multiple Answer)
                              // </option>
                            }
                            <option value="3">Numeric</option>
                            <option value="4">Date</option>
                            <option value="5">Text</option>
                          </select>
                          <div className="text-red-700">
                            {(formik.errors.survey &&
                              formik.errors.survey[i]?.kind) ||
                              ""}
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id={`question_options-${i}`}
                          placeholder="Choices"
                          value={v.options}
                          disabled={
                            (v.kind !== 1 && v.kind !== 2) ||
                            (isEdit && v.id != null)
                          }
                          onChange={(e) => {
                            formik.setFieldValue("survey", [
                              ...survey.slice(0, i),
                              {
                                question: v.question,
                                kind: v.kind,
                                options: e.target.value,
                              },
                              ...survey.slice(i + 1),
                            ]);
                          }}
                        ></input>
                        <div className="text-red-700">
                          {(formik.errors.survey &&
                            formik.errors.survey[i]?.options) ||
                            ""}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        {isEdit && v.id != null ? (
                          <></>
                        ) : (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              formik.setFieldValue("survey", [
                                ...survey.slice(0, i),
                                ...survey.slice(i + 1),
                              ]);
                            }}
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              margin: "2px",
                            }}
                          >
                            X
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <Button
                  size="sm"
                  onClick={() => {
                    formik.setFieldValue("survey", [
                      ...survey,
                      { question: "", kind: 0, options: "" },
                    ]);
                  }}
                >
                  Add New Question
                </Button>
              </Stack>
            </Center>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function ShowDetailsElement(props: { project: Project | null }): ReactElement {
  if (props.project === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Sites</Tab>
        <Tab>Files ({props.project.files.length})</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack>
            <Table size="sm" variant="striped">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Title</Td>
                </Tr>
                <Tr>
                  <Td>{props.project.title}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Expected Recruitment</Td>
                </Tr>
                <Tr>
                  <Td>{props.project.expected_recruitment}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Actual Recruitment</Td>
                </Tr>
                <Tr>
                  <Td>{props.project.actual_recruitment}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Start Date</Td>
                </Tr>
                <Tr>
                  <Td>{props.project.start_date}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">End Date</Td>
                </Tr>
                <Tr>
                  <Td>{props.project.end_date}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Notes</Td>
                </Tr>
                <Tr>
                  <Td maxW="xl" sx={{ "text-wrap": "balance" }}>
                    {props.project.notes || "None"}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Site Name</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.project.sites.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link href={`/sites?id=${c.id}&mode=2`} isExternal>
                        {c.name}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td maxW="xl" sx={{ "text-wrap": "balance" }}>
                      {`${c.notes}` || "None"}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Filename</Th>
                  <Th fontWeight="bold">Uploaded By</Th>
                  <Th fontWeight="bold">Uploaded At</Th>
                  <Th fontWeight="bold">Notes</Th>
                  <Th fontWeight="bold"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.project.files.map((c) => (
                  <Tr key={c.id}>
                    <Td>{c.name}</Td>
                    <Td>{c.uploaded_by}</Td>
                    <Td>{c.created_at.slice(0, 19).replace("T", " ")}</Td>
                    <Td maxW="xl" sx={{ textWrap: "balance" }}>
                      {c.notes}
                    </Td>
                    <Td>
                      <IconButton
                        size="xs"
                        icon={<FaDownload />}
                        variant="tertiary"
                        title={"Download"}
                        aria-label={"Download"}
                        onClick={() => {
                          get_file_link(c.id).then((res) => {
                            window.open(res.data, "_newtab");
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
