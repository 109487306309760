import {
  Pagination as ArkPagination,
  PaginationProps as ArkPaginationProps,
  PaginationEllipsis,
  PaginationNextPageTrigger,
  PaginationPageTrigger,
  PaginationPrevPageTrigger,
} from "@ark-ui/react";
import {
  Button,
  Center,
  IconButton,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

export type PaginationProps = ArkPaginationProps;

export function useSearch(
  defaults?: {
    page?: string;
    pageSize?: string;
    mode?: string;
    id?: string;
    query?: string;
    sort?: string;
  },
  ...others: { name: string; value?: string; kind?: string }[]
) {
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (params.get("page") === null) {
      params.set("page", defaults?.page || "1");
      setParams(params);
    }
    if (params.get("pageSize") === null) {
      params.set("pageSize", defaults?.pageSize || "10");
      setParams(params);
    }
    if (params.get("mode") === null) {
      params.set("mode", defaults?.mode || "-1");
      setParams(params);
    }
    if (params.get("id") === null) {
      params.set("id", defaults?.id || "-1");
      setParams(params);
    }
    if (params.get("query") === null) {
      params.set("query", defaults?.query || "");
      setParams(params);
    }
    if (params.get("sort") === null) {
      params.set("sort", defaults?.sort || "");
      setParams(params);
    }

    others.forEach(({ name, value }) => {
      params.set(name, value || "");
      setParams(params);
    });

  }, [params, setParams, defaults]);

  return [
    parseInt(params.get("page") || "1"),
    parseInt(params.get("pageSize") || "10"),
    parseInt(params.get("mode") || "-1"),
    parseInt(params.get("id") || "-1"),
    params.get("query") || "",
    params.get("sort") || "",
  ].concat(
    others.map(({ name, kind }) =>
      kind === "integer"
        ? parseInt(params.get(name) || "-1")
        : params.get(name) || "",
    ),
  );
}

export const Pagination = (props: PaginationProps) => {
  return (
    <ArkPagination.Root {...props}>
      {({ pages, page }) => (
        <List display="flex" justifyContent="space-between">
          <ListItem>
            <PaginationPrevPageTrigger asChild>
              <IconButton
                variant="secondary"
                isRound
                icon={<FiArrowLeft />}
                aria-label="Previous Page"
              />
            </PaginationPrevPageTrigger>
          </ListItem>

          <Stack dir="row">
            <List m="0 auto" display={{ base: "none", md: "flex" }} gap="1">
              {pages.map((page, index) =>
                page.type === "page" ? (
                  <ListItem key={index}>
                    <PaginationPageTrigger asChild {...page}>
                      <Button
                        variant="secondary"
                        borderRadius="full"
                        _selected={{ bg: "gray.50", _dark: { bg: "gray.800" } }}
                        onClick={() => {
                          if (props.onChange)
                            props.onChange({
                              page: page.value,
                              pageSize: props.pageSize || 0,
                            });
                        }}
                      >
                        {page.value}
                      </Button>
                    </PaginationPageTrigger>
                  </ListItem>
                ) : (
                  <ListItem key={index} alignItems="center" display="flex">
                    <PaginationEllipsis index={index}>
                      <Button
                        variant="secondary"
                        borderRadius="full"
                        pointerEvents="none"
                        width="10"
                      >
                        &#8230;
                      </Button>
                    </PaginationEllipsis>
                  </ListItem>
                ),
              )}
            </List>
            <Center>
              <Text m="0 auto" fontWeight="medium" color="fg.emphasized">
                {props.count} item{props.count !== 1 && "s"} in total
              </Text>
            </Center>
          </Stack>
          <ListItem as={Center} display={{ md: "none" }}>
            <Text fontWeight="medium" color="fg.emphasized">
              Page {page} of {pages.length + 1}
            </Text>
          </ListItem>

          <ListItem>
            <PaginationNextPageTrigger asChild>
              <IconButton
                variant="secondary"
                isRound
                icon={<FiArrowRight />}
                aria-label="Next Page"
              />
            </PaginationNextPageTrigger>
          </ListItem>
        </List>
      )}
    </ArkPagination.Root>
  );
};
