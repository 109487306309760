import { Navigate, Route } from "react-router-dom";
import { KitManagement } from "./pages/assets/kits";
import { ProjectManagement } from "./pages/assets/projects";
import { PumpManagement } from "./pages/assets/pumps";
import { SiteManagement } from "./pages/assets/sites";
import { TubeManagement } from "./pages/assets/tubes";
import { LogsView } from "./pages/audit/logs";
import { FileManagement } from "./pages/data/data";
import { PatientManagement } from "./pages/data/patients";
import { HomePage } from "./pages/homepage/App";
import { LoginLink } from "./pages/login/LoginLink";
import { LoginPage } from "./pages/login/Main";
import { Settings } from "./pages/settings/Main";
import { UserManagement } from "./pages/users/Main";

export function getRoutes(permissions: { verb: number; resource: number }[]) {
  return (
    <>
      <Route
        path="/"
        element={
          <Navigate
            to={
              permissions[0].verb
                ? "/users"
                : permissions[1].verb
                ? "/patients"
                : permissions[2].verb
                ? "/data"
                : permissions[3].verb
                ? "/projects"
                : permissions[4].verb
                ? "/pumps"
                : permissions[5].verb
                ? "/logs"
                : "/unauthorized"
            }
            replace
          />
        }
      />
      {permissions[0].verb > 0 && (
        <Route
          path="/users"
          element={
            <HomePage>
              <UserManagement />
            </HomePage>
          }
        />
      )}
      {permissions[1].verb > 0 && (
        <Route
          path="/patients"
          element={
            <HomePage>
              <PatientManagement />
            </HomePage>
          }
        />
      )}
      {permissions[2].verb > 0 && (
        <Route
          path="/data"
          element={
            <HomePage>
              <FileManagement />
            </HomePage>
          }
        />
      )}
      {permissions[3].verb > 0 && (
        <>
          <Route
            path="/projects"
            element={
              <HomePage>
                <ProjectManagement />
              </HomePage>
            }
          />
          <Route
            path="/sites"
            element={
              <HomePage>
                <SiteManagement />
              </HomePage>
            }
          />
        </>
      )}
      {permissions[4].verb > 0 && (
        <>
          <Route
            path="/pumps"
            element={
              <HomePage>
                <PumpManagement />
              </HomePage>
            }
          />
          <Route
            path="/kits"
            element={
              <HomePage>
                <KitManagement />
              </HomePage>
            }
          />
          <Route
            path="/tubes"
            element={
              <HomePage>
                <TubeManagement />
              </HomePage>
            }
          />
        </>
      )}
      {permissions[5].verb > 0 && (
        <>
          <Route
            path="/logs"
            element={
              <HomePage>
                <LogsView />
              </HomePage>
            }
          />
        </>
      )}
      <Route
        path="/settings"
        element={
          <HomePage>
            <Settings />
          </HomePage>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );
}

export const demoRoutes = (
  <>
    <Route path="/" element={<Navigate to="/users" replace />} />
    <Route
      path="/users"
      element={
        <HomePage>
          <UserManagement />
        </HomePage>
      }
    />
    <Route
      path="/patients"
      element={
        <HomePage>
          <PatientManagement />
        </HomePage>
      }
    />
    <Route
      path="/data"
      element={
        <HomePage>
          <FileManagement />
        </HomePage>
      }
    />
    <Route
      path="/projects"
      element={
        <HomePage>
          <ProjectManagement />
        </HomePage>
      }
    />
    <Route
      path="/sites"
      element={
        <HomePage>
          <SiteManagement />
        </HomePage>
      }
    />
    <Route
      path="/pumps"
      element={
        <HomePage>
          <PumpManagement />
        </HomePage>
      }
    />
    <Route
      path="/kits"
      element={
        <HomePage>
          <KitManagement />
        </HomePage>
      }
    />
    <Route
      path="/tubes"
      element={
        <HomePage>
          <TubeManagement />
        </HomePage>
      }
    />
    <Route
      path="/logs"
      element={
        <HomePage>
          <LogsView />
        </HomePage>
      }
    />
    <Route
      path="/settings"
      element={
        <HomePage>
          <Settings />
        </HomePage>
      }
    />
  </>
);

export function getNonLoggedRoutes(level: number) {
  const nonLoggedInRoutes = (
    <>
      <Route path="/" element={<LoginPage step={0} />} />
      <Route path="/login_link" element={<LoginLink />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );

  const forcePasswordChangeRoutes = (
    <>
      <Route path="/" element={<LoginPage step={1} />} />,
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );

  const forceEnableMFARoutes = (
    <>
      <Route path="/" element={<LoginPage step={2} />} />,
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );

  const enterMFACodeRoutes = (
    <>
      <Route path="/" element={<LoginPage step={3} />} />,
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );
  const routes = [
    nonLoggedInRoutes, // level 0
    forcePasswordChangeRoutes, // level 1
    forceEnableMFARoutes, // level 2
    enterMFACodeRoutes, // level 3
  ];

  return routes[level];
}
