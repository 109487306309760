import axios from "axios";
import { Question, Tube } from "./Models";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const BULK_SEND_PATH = `${BASE_URL}/bulk_send`;
const CHANGE_PASSWORD_PATH = `${BASE_URL}/change_password`;
const ENABLE_MFA_PATH = `${BASE_URL}/enable_mfa`;
const FILES_PATH = `${BASE_URL}/files`;
const FILE_PATH = `${BASE_URL}/file`;
const FORGOT_PASSWORD_PATH = `${BASE_URL}/forgot_password`;
const INFO_PATH = `${BASE_URL}/info`;
const KITS_PATH = `${BASE_URL}/kits`;
const KIT_PATH = `${BASE_URL}/kit`;
const IMPORT_PATH = `${BASE_URL}/import`;
const LOGS_PATH = `${BASE_URL}/logs`;
const LOGIN_LINK_PATH = `${BASE_URL}/login_link`;
const LOGIN_PATH = `${BASE_URL}/login`;
const LOGOUT_PATH = `${BASE_URL}/logout`;
const MFA_PATH = `${BASE_URL}/mfa`;
const PUMPS_PATH = `${BASE_URL}/pumps`;
const PUMP_PATH = `${BASE_URL}/pump`;
export const REFRESH_PATH = `${BASE_URL}/refresh`;
const SITES_PATH = `${BASE_URL}/sites`;
const SITE_PATH = `${BASE_URL}/site`;
const TUBES_PATH = `${BASE_URL}/tubes`;
const TUBE_PATH = `${BASE_URL}/tube`;
const PATIENT_PATH = `${BASE_URL}/patient`;
const PATIENTS_PATH = `${BASE_URL}/patients`;
const PATIENTS_EXPORT_PATH = `${BASE_URL}/patients/export`;
const PREFILL_PATH = `${BASE_URL}/prefill`;
const PROJECTS_PATH = `${BASE_URL}/projects`;
const PROJECT_PATH = `${BASE_URL}/project`;
const USERS_PATH = `${BASE_URL}/users`;
const USER_PATH = `${BASE_URL}/user`;

export function login(data: { email: string; password: string }): Promise<any> {
  return axios.post(LOGIN_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function get_pump(id: number): Promise<any> {
  return axios.get(`${PUMP_PATH}/${id}`);
}

export function get_kit(id: number): Promise<any> {
  return axios.get(`${KIT_PATH}/${id}`);
}

export function get_project(id: number): Promise<any> {
  return axios.get(`${PROJECT_PATH}/${id}`);
}

export function get_site(id: number): Promise<any> {
  return axios.get(`${SITE_PATH}/${id}`);
}

export function get_patient(id: string, project_id: number): Promise<any> {
  return axios.get(`${PATIENT_PATH}`, { params: { id, project_id } });
}

export function get_patients_statistics(project_id: number): Promise<any> {
  return axios.get(`${PROJECT_PATH}/${project_id}/statistics`);
}

export function get_file(id: number): Promise<any> {
  return axios.get(`${FILE_PATH}/${id}`);
}

export function get_pumps(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(PUMPS_PATH, { params: { page, limit, query, sort } });
}

export function get_files(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(FILES_PATH, { params: { page, limit, query, sort } });
}

export function get_kits(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(KITS_PATH, { params: { page, limit, query, sort } });
}

export function get_users(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(USERS_PATH, { params: { page, limit, query, sort } });
}

export function get_patients(project_id: number): Promise<any> {
  return axios.get(PATIENTS_PATH, { params: { project_id } });
}

export function patients_export(body: {
  project_id: number;
  filters: any;
}): Promise<any> {
  return axios.post(PATIENTS_EXPORT_PATH, body);
}

export function change_password(data: {
  password: string;
  self: boolean;
}): Promise<any> {
  return axios.post(CHANGE_PASSWORD_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function get_enable_mfa(): Promise<any> {
  return axios.get(ENABLE_MFA_PATH);
}

export function enable_mfa(data: { code: string }): Promise<any> {
  return axios.post(ENABLE_MFA_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mfa(data: { code: string }): Promise<any> {
  return axios.post(MFA_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function forgot_password(data: { email: string }): Promise<any> {
  return axios.post(FORGOT_PASSWORD_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function logout(): Promise<any> {
  return axios.post(LOGOUT_PATH);
}

export function edit_user(data: {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  permissions: number[];
}): Promise<any> {
  const email = data.email;

  return axios.patch(USER_PATH + "/" + email, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function add_user(data: {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  permissions: number[];
}): Promise<any> {
  return axios.put(USER_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_user(email: string): Promise<any> {
  return axios.delete(USER_PATH + "/" + email);
}

export function reset_mfa(email: string): Promise<any> {
  return axios.post(USER_PATH + "/" + email + "/reset_mfa");
}

export function refresh(): Promise<any> {
  return axios.post(REFRESH_PATH);
}

export function login_link(data: { token: string }): Promise<any> {
  return axios.post(LOGIN_LINK_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function user_info(): Promise<any> {
  return axios.get(INFO_PATH);
}

export function add_pump(data: {
  name: string;
  number: string;
  charger: string;
  notes: string;
}): Promise<any> {
  return axios.put(PUMP_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_pump(
  id: number,
  data: {
    name: string;
    number: string;
    charger: string;
    notes: string;
  },
): Promise<any> {
  return axios.patch(PUMP_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_pump(id: number): Promise<any> {
  return axios.delete(PUMP_PATH + "/" + id);
}

export function calibrate_pump(
  id: number,
  data: {
    reading_before: number;
    reading_after: number;
    target_reading: number | null;
    date: string;
  },
): Promise<any> {
  return axios.post(`${PUMP_PATH}/${id}/calibrate`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function get_file_upload_link(file: string) {
  return axios.post(
    `${FILE_PATH}/upload_link`,
    { filename: file },
    {
      headers: { "Content-Type": "application/json" },
    },
  );
}

export function get_file_link(id: number) {
  return axios.get(`${FILE_PATH}/${id}/link`);
}

export function add_file(data: {
  name: string;
  filename: string;
  patient_id: string;
  notes: string;
}): Promise<any> {
  return axios.put(FILE_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_file(
  id: number,
  data: {
    notes: string;
  },
): Promise<any> {
  return axios.patch(FILE_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function add_kit(data: {
  number: string;
  make_date: string | null;
  bag_conditioned: string | null;
  tubes: Tube[];
  notes: string;
}): Promise<any> {
  return axios.put(KIT_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_kit(
  id: number,
  data: {
    number: string;
    make_date: string | null;
    bag_conditioned: string | null;
    tubes: Tube[];
    notes: string;
  },
): Promise<any> {
  return axios.patch(KIT_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_kit(id: number): Promise<any> {
  return axios.delete(KIT_PATH + "/" + id);
}

export function get_sites(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(SITES_PATH, { params: { page, limit, query, sort } });
}

export function get_projects(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(PROJECTS_PATH, { params: { page, limit, query, sort } });
}

export function add_site(data: {
  code: string;
  project_id: number;
  name: string;
  notes: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  prompt: string;
}): Promise<any> {
  return axios.put(SITE_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_site(
  id: number,
  data: {
    code: string;
    project_id: number;
    name: string;
    notes: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    prompt: string;
  },
): Promise<any> {
  return axios.patch(SITE_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_site(id: number): Promise<any> {
  return axios.delete(SITE_PATH + "/" + id);
}

export function add_project(data: {
  title: string;
  expected_recruitment: number;
  start_date: string;
  end_date: string;
  survey: Question[];
  notes: string;
}): Promise<any> {
  return axios.put(PROJECT_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_project(
  id: number,
  data: {
    title: string;
    expected_recruitment: number;
    start_date: string;
    end_date: string;
    survey: Object[];
    notes: string;
  },
): Promise<any> {
  return axios.patch(PROJECT_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_received_kit(
  id: number,
  data: {
    received: string;
  },
): Promise<any> {
  return axios.post(`${KIT_PATH}/${id}/mark_received`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_returned_kit(
  id: number,
  data: {
    received: string;
  },
): Promise<any> {
  return axios.post(`${KIT_PATH}/${id}/mark_returned`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_lost_kit(id: number): Promise<any> {
  return axios.post(`${KIT_PATH}/${id}/mark_lost`);
}

export function send_kit(
  id: number,
  data: {
    frm: number;
    to: number;
    sent: string;
    received: string | null;
    tracking_number: string;
  },
): Promise<any> {
  return axios.post(`${KIT_PATH}/${id}/send`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_received_pump(
  id: number,
  data: {
    received: string;
  },
): Promise<any> {
  return axios.post(`${PUMP_PATH}/${id}/mark_received`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_returned_pump(
  id: number,
  data: {
    received: string;
  },
): Promise<any> {
  return axios.post(`${PUMP_PATH}/${id}/mark_returned`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function mark_lost_pump(id: number): Promise<any> {
  return axios.post(`${PUMP_PATH}/${id}/mark_lost`);
}

export function send_pump(
  id: number,
  data: {
    frm: number;
    to: number;
    sent: string;
    received: string | null;
    tracking_number: string;
  },
): Promise<any> {
  return axios.post(`${PUMP_PATH}/${id}/send`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function get_tubes(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(TUBES_PATH, { params: { page, limit, query, sort } });
}

export function get_tube(id: number): Promise<any> {
  return axios.get(`${TUBE_PATH}/${id}`);
}

export function add_tube(data: {
  kit_id: number;
  number: string;
  part_number: string;
  flow_rate: number;
  kind: number;
  state: number;
  notes: string;
}): Promise<any> {
  return axios.put(TUBE_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_tube(
  id: number,
  data: {
    kit_id: number;
    number: string;
    part_number: string;
    flow_rate: number;
    state: number;
    notes: string;
  },
): Promise<any> {
  return axios.patch(TUBE_PATH + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function condition_tube(
  id: number,
  data: {
    date: string;
    notes: string;
  },
): Promise<any> {
  return axios.post(`${TUBE_PATH}/${id}/condition`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function bulk_condition_tubes(data: {
  tubes: string[];
  run_date: string;
  date: string;
  notes: string;
}): Promise<any> {
  return axios.post(`${TUBES_PATH}/bulk_condition`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function bulk_state(data: {
  tubes: string[];
  run_date: string;
  date: string;
  kind: number;
  state: number;
  kit_id: number;
  notes: string;
}): Promise<any> {
  return axios.post(`${TUBES_PATH}/bulk_state`, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_tube(id: number): Promise<any> {
  return axios.delete(TUBE_PATH + "/" + id);
}

export function add_patient(data: {
  id: string;
  site_id: number;
  sex_at_birth: number;
  age: number;
  birth_country?: string;
  height: number;
  weight: number;
  bmi: number;
  answers: {};
  filename?: string | null;
}): Promise<any> {
  return axios.put(PATIENT_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function edit_patient(
  id: string,
  project_id: string,
  data: {
    site_id: number;
    sex_at_birth: number;
    age: number;
    birth_country?: string;
    height: number;
    weight: number;
    bmi: number;
    answers: {};
  },
): Promise<any> {
  return axios.patch(PATIENT_PATH + "/" + project_id + "/" + id, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function delete_patient(id: string): Promise<any> {
  return axios.delete(PATIENT_PATH + "/" + id);
}

export function get_logs(
  page: number,
  limit: number,
  query: string,
  sort: string,
): Promise<any> {
  return axios.get(LOGS_PATH, { params: { page, limit, query, sort } });
}

export function import_csv(data: {
  resource: string;
  data: string;
}): Promise<any> {
  return axios.post(
    `${IMPORT_PATH}/${data.resource}`,
    { data: data.data },
    {
      headers: { "Content-Type": "application/json" },
    },
  );
}

export function get_prefill(site_id: number, job_id: string): Promise<any> {
  return axios.get(`${PREFILL_PATH}/${site_id}/${job_id}`);
}

export function prefill_from_file(data: {
  filename: string;
  project_id: number;
  site_id: number;
}): Promise<any> {
  return axios.post(PREFILL_PATH, data, {
    headers: { "Content-Type": "application/json" },
  });
}

export function bulk_send(data: {
  assets: string[];
  frm: number;
  to: number;
  sent: string;
  received: string | null;
  tracking_number: string;
}): Promise<any> {
  return axios.post(`${BULK_SEND_PATH}`, data, {
    headers: { "Content-Type": "application/json" },
  });
}
