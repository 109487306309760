import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { FiEdit2, FiSearch, FiTrash2 } from "react-icons/fi";
import { MdOutlineHardware } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import Papa from "papaparse";
import {
  add_site,
  delete_site,
  edit_site,
  get_file_link,
  get_projects,
  get_site,
  get_sites,
  import_csv,
} from "../../common/Api";
import { download_csv_sample, LoadingSpinner } from "../../common/Components";
import {
  AddSiteSchema,
  EditSiteSchema,
  Project,
  Site,
  validateCSV,
} from "../../common/Models";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable } from "../users/MemberTable";
import { GeneralModal } from "../users/Modal";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { BiDetail } from "react-icons/bi";
import { FaDownload } from "react-icons/fa6";
import { getPermissionList } from "../../token";

enum ModalModes {
  ADD,
  EDIT,
  SHOW_DETAILS,
  IMPORT,
}

export function SiteManagement() {
  const toast = useToast();

  const permissions = getPermissionList();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, id, query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(1);
  const [sites, setSites] = useState(new Array<Site>());
  const [projects, setProjects] = useState(new Array<Project>());

  const [site, setSite] = useState(null as Site | null);
  const [queryBuffer, setQueryBuffer] = useState(query as string);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("id", "0");
        return params;
      });
      setSite(null);
    },
  });

  const createForm = useFormik({
    initialValues: {
      code: "",
      project_id: 0,
      name: "",
      notes: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      prompt: "",
    },
    validationSchema: AddSiteSchema,
    onSubmit: async (values) => {
      toast.promise(
        add_site(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
          createForm.resetForm();
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });

  const editForm = useFormik({
    initialValues: {
      code: "",
      project_id: 0,
      name: "",
      notes: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      prompt: "",
    },
    validationSchema: EditSiteSchema,
    onSubmit: async (values) => {
      toast.promise(
        edit_site(id as number, values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.EDIT:
        get_site(id as number)
          .then((res) => {
            const site = res.data as Site;
            setSite(site);
            editForm.setValues({
              code: site.code || "",
              project_id: site.project_id || 0,
              name: site.name || "",
              notes: site.notes || "",
              address1: site.address1 || "",
              address2: site.address2 || "",
              city: site.city || "",
              state: site.state || "",
              country: site.country || "",
              postal_code: site.postal_code || "",
              contact_name: site.contact_name || "",
              contact_email: site.contact_email || "",
              contact_phone: site.contact_phone || "",
              prompt: site.prompt || "",
            });
          })
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          });
        break;
      case ModalModes.SHOW_DETAILS:
        get_site(id as number)
          .then((res) => setSite(res.data))
          .catch(() => {
            toast({ title: "Failed to Get", status: "error" });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case -1:
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (mode !== -1) return;
    setIsLoading(true);
    get_sites(
      page as number,
      pageSize as number,
      query as string,
      sort as string,
    )
      .then((res) => {
        const d = res.data.sites as Array<Site>;
        const t = res.data.total;
        setSites(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  useEffect(() => {
    get_projects(1, 0, "", "").then((res) => {
      const d = res.data.projects as Array<Project>;
      setProjects(d);
    });
  }, []);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack direction={{ base: "column", md: "row" }} justify="end">
              {(permissions[3].verb & 1) != 0 && (
                <InputGroup maxW="xs">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    onKeyUp={(e) => {
                      if (e.key !== "Enter") return;

                      setSearchParams((params) => {
                        params.set("query", queryBuffer);
                        params.set("page", "1");
                        return params;
                      });
                    }}
                    autoFocus={true}
                    onChange={(e) => {
                      setQueryBuffer(e.target.value);
                    }}
                    value={queryBuffer}
                  />
                </InputGroup>
              )}

              {(permissions[3].verb & 2) != 0 && (
                <>
                  <FormControl
                    colorScheme="blue"
                    justifyContent="end"
                    display="flex"
                  >
                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-l-lg text-sm font-bold px-2.5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700"
                      title="Download a Sample CSV"
                      onClick={()=>{download_csv_sample("sites")}}
                    >
                      i
                    </button>
                    <FormLabel
                      htmlFor="file_button"
                      textColor="white"
                      backgroundColor="#5728b5"
                      cursor="pointer"
                      py={2.5}
                      px={4}
                      rounded={5}
                      roundedLeft={0}
                      m={0}
                      w="max-content"
                      fontWeight="bold"
                      _hover={{
                        backgroundColor: "#6530cf",
                      }}
                    >
                      Import CSV
                    </FormLabel>
                    <Input
                      id="file_button"
                      name="file"
                      type="file"
                      display="none"
                      accept=".csv"
                      onChange={(e) => {
                        const files = e.currentTarget.files;
                        if (!files) return;
                        const file = files[0];
                        const reader = new FileReader();
                        reader.onload = ({ target }) => {
                          if (!target) return;
                          const columns = [
                            "code",
                            "name",
                            "project",
                            "notes",
                            "address1",
                            "address2",
                            "city",
                            "state",
                            "country",
                            "postal_code",
                          ];
                          const csv = Papa.parse<any>(target.result as string, {
                            header: true,
                          });
                          const errors = validateCSV(csv, columns);
                          if (errors.length !== 0) {
                            toast({
                              title: "Errors loading csv file",
                              description: errors.join(" | "),
                              status: "error",
                            });
                            return;
                          }

                          const tid = toast({
                            title: "Importing ...",
                            status: "loading",
                            duration: null,
                          });
                          import_csv({
                            resource: "sites",
                            data: target.result as string,
                          })
                            .then(() => {
                              toast.update(tid, {
                                status: "success",
                                title: "Finished importing successfully",
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            })
                            .catch((err) => {
                              toast.update(tid, {
                                status: "error",
                                title: "Failed to import",
                                description: err.response.data.join(" | "),
                                isClosable: true,
                                duration: null,
                                onCloseComplete: () => window.location.reload(),
                              });
                            });
                        };

                        reader.readAsText(file);
                      }}
                    />
                  </FormControl>
                  <Button
                    onClick={() => {
                      setSearchParams((params) => {
                        params.set("id", "-1");
                        params.set("mode", ModalModes.ADD.toString());
                        return params;
                      });
                    }}
                    id="add"
                  >
                    Add Site
                  </Button>
                </>
              )}
            </Stack>
          </Box>
          {(permissions[3].verb & 1) != 0 && (
            <Box overflowX="auto">
              <MyTable<Site>
                sortColumn={sort as string}
                setSearchParams={setSearchParams}
                rowAction={(p) => {
                  setSearchParams((params) => {
                    params.set("id", p.id.toString());
                    params.set("mode", ModalModes.SHOW_DETAILS.toString());
                    return params;
                  });
                }}
                columns={[
                  { name: "Code", width: "10%", sort_id: "code" },
                  { name: "Site Name", width: "15%", sort_id: "name" },
                  { name: "Project", width: "25%", sort_id: "project_title" },
                  { name: "Recruited Patients", width: "20%" },
                  { name: "Country", width: "20%" },
                  { name: "Actions", width: "15%" },
                ]}
                data={sites}
                bindings={[
                  (r: Site) => r.code,
                  (r: Site) => r.name,
                  (r: Site) => r.project.title,
                  (r: Site) => r.recruited_patients,
                  (r: Site) => r.country || "Not Entered",
                ]}
                rk={(r: Site) => `${r.id}`}
                functions={[
                  {
                    title: "Edit",
                    icon: <FiEdit2 />,
                    fn: (p: Site) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.EDIT.toString());
                        return params;
                      });
                    },
                    condition: () => (permissions[3].verb & 2) != 0,
                  },
                  {
                    title: "Show Activity & Assets",
                    icon: <BiDetail />,
                    fn: (p) => {
                      setSearchParams((params) => {
                        params.set("id", p.id.toString());
                        params.set("mode", ModalModes.SHOW_DETAILS.toString());
                        return params;
                      });
                    },
                  },
                  {
                    title: "Delete",
                    icon: <FiTrash2 color="darkred" />,
                    condition: () => (permissions[3].verb & 4) != 0,
                    fn: (p) => {
                      const yes = window.confirm(
                        `Are you sure you want to delete the site "${p.name}"? This action cannot be undone`,
                      );

                      if (!yes) return;

                      toast.promise(
                        delete_site(p.id).then(() => {
                          setSearchParams((params) => {
                            params.set("mode", "-1");
                            return params;
                          });
                          window.location.reload();
                        }),
                        {
                          success: { title: "Successfully Deleted" },
                          error: {
                            title: "Failed to Delete Site",
                            description: "Please contact support",
                          },
                          loading: { title: "Deleting..." },
                        },
                      );
                    },
                  },
                ]}
              />
            </Box>
          )}
          {(permissions[3].verb & 1) != 0 && (
            <Box bg="bg.surface">
              <Container>
                <Pagination
                  count={totalCount}
                  pageSize={pageSize as number}
                  siblingCount={2}
                  page={page as number}
                  onChange={(e: any) => {
                    setSearchParams((params) => {
                      params.set("page", e.page);
                      return params;
                    });
                  }}
                />
              </Container>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 my-3 mx-auto"
                onChange={(e) => {
                  setSearchParams((params) => {
                    params.set("pageSize", e.target.value);
                    return params;
                  });
                }}
                value={pageSize}
              >
                <option value="10">10 Results per Page</option>
                <option value="20">20 Results per Page</option>
                <option value="30">30 Results per Page</option>
                <option value="50">50 Results per Page</option>
                <option value="100">100 Results per Page</option>
              </select>
            </Box>
          )}
        </Stack>
      </Box>
      {(permissions[3].verb & 2) != 0 && mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          body={
            <AddSiteElement
              formik={createForm}
              disabled={[]}
              projects={projects}
            />
          }
          title={"Add Site"}
          buttonText="Save"
        />
      )}
      {(permissions[3].verb & 2) != 0 && mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={
            <AddSiteElement
              formik={editForm}
              disabled={["project_id"]}
              projects={projects}
            />
          }
          title={"Edit Site"}
          buttonText="Save"
        />
      )}
      {(permissions[3].verb & 1) != 0 && mode === ModalModes.SHOW_DETAILS && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          body={<ShowDetailsElement site={site} />}
          title={"Activity & Assets"}
        />
      )}
    </Container>
  );
}

function AddSiteElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;
  const projects: Array<Project> = props.projects || [];

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box w="100%" m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="code"
                isInvalid={formik.errors.code && formik.touched.code}
                isDisabled={disabled.filter((v) => v === "code").length !== 0}
              >
                <FormLabel>Code</FormLabel>
                <Input
                  id="code"
                  name="code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                />
                <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="name"
                isInvalid={formik.errors.name && formik.touched.name}
                isDisabled={disabled.filter((v) => v === "name").length !== 0}
              >
                <FormLabel>Site Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="project_id"
                isInvalid={
                  formik.errors.project_id && formik.touched.project_id
                }
                isDisabled={
                  disabled.filter((v) => v === "project_id").length !== 0
                }
              >
                <FormLabel>Project</FormLabel>
                <Select
                  placeholder="Select option"
                  id="project_id"
                  name="project_id"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "project_id",
                      parseInt(e.target.value),
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.project_id}
                >
                  {projects.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.title}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.project_id}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="address1"
                isInvalid={formik.errors.address1 && formik.touched.address1}
                isDisabled={
                  disabled.filter((v) => v === "address1").length !== 0
                }
              >
                <FormLabel>Address</FormLabel>
                <Input
                  id="address1"
                  name="address1"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address1}
                />
                <FormErrorMessage>{formik.errors.address1}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="address2"
                isInvalid={formik.errors.address2 && formik.touched.address2}
                isDisabled={
                  disabled.filter((v) => v === "address2").length !== 0
                }
              >
                <FormLabel>Address 2</FormLabel>
                <Input
                  id="address2"
                  name="address2"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2}
                />
                <FormErrorMessage>{formik.errors.address2}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="city"
                isInvalid={formik.errors.city && formik.touched.city}
                isDisabled={disabled.filter((v) => v === "city").length !== 0}
              >
                <FormLabel>City</FormLabel>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
                <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="state"
                isInvalid={formik.errors.state && formik.touched.state}
                isDisabled={disabled.filter((v) => v === "state").length !== 0}
              >
                <FormLabel>State</FormLabel>
                <Input
                  id="state"
                  name="state"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                />
                <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="country"
                isInvalid={formik.errors.country && formik.touched.country}
                isDisabled={
                  disabled.filter((v) => v === "country").length !== 0
                }
              >
                <FormLabel>Country</FormLabel>
                <Input
                  id="country"
                  name="country"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                />
                <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="postal_code"
                isInvalid={
                  formik.errors.postal_code && formik.touched.postal_code
                }
                isDisabled={
                  disabled.filter((v) => v === "postal_code").length !== 0
                }
              >
                <FormLabel>Postal Code</FormLabel>
                <Input
                  id="postal_code"
                  name="postal_code"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postal_code}
                />
                <FormErrorMessage>{formik.errors.postal_code}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="contact_phone"
                isInvalid={
                  formik.errors.contact_phone && formik.touched.contact_phone
                }
                isDisabled={
                  disabled.filter((v) => v === "contact_phone").length !== 0
                }
              >
                <FormLabel>Contact Phone</FormLabel>
                <Input
                  id="contact_phone"
                  name="contact_phone"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact_phone}
                />
                <FormErrorMessage>
                  {formik.errors.contact_phone}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="contact_email"
                isInvalid={
                  formik.errors.contact_email && formik.touched.contact_email
                }
                isDisabled={
                  disabled.filter((v) => v === "contact_email").length !== 0
                }
              >
                <FormLabel>Contact Email</FormLabel>
                <Input
                  id="contact_email"
                  name="contact_email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact_email}
                />
                <FormErrorMessage>
                  {formik.errors.contact_email}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="contact_name"
                isInvalid={
                  formik.errors.contact_name && formik.touched.contact_name
                }
                isDisabled={
                  disabled.filter((v) => v === "contact_name").length !== 0
                }
              >
                <FormLabel>Contact Name</FormLabel>
                <Input
                  id="contact_name"
                  name="contact_name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact_name}
                />
                <FormErrorMessage>
                  {formik.errors.contact_name}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Prompt (Document Parsing)</FormLabel>
                <Textarea
                  id="prompt"
                  name="prompt"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.prompt}
                />
                <FormErrorMessage>{formik.errors.prompt}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", lg: "row" }}
              spacing={{ base: "5", lg: "8" }}
              justify="space-between"
            >
              <FormControl
                id="notes"
                isInvalid={formik.errors.notes && formik.touched.notes}
                isDisabled={disabled.filter((v) => v === "notes").length !== 0}
              >
                <FormLabel>Notes (255 characters max)</FormLabel>
                <Textarea
                  id="notes"
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.notes}
                  maxLength={255}
                />
                <FormErrorMessage>{formik.errors.notes}</FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

function ShowDetailsElement(props: { site: Site | null }): ReactElement {
  if (props.site === null) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <Tabs variant="soft-rounded" colorScheme="blue">
      <TabList>
        <Tab>Overview</Tab>
        <Tab>Assets On-Site</Tab>
        <Tab>Shipping Log</Tab>
        <Tab>Patients ({props.site.recruited_patients})</Tab>
        <Tab>Files ({props.site.files.length})</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack>
            <Table size="sm" variant="striped">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Project</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Link
                      href={`/projects?id=${props.site.project_id}&mode=2`}
                      isExternal
                    >
                      {props.site.project.title}
                      <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Site Name</Td>
                </Tr>
                <Tr>
                  <Td>{props.site.name}</Td>
                </Tr>
                {props.site.code && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Code</Td>
                    </Tr>
                    <Tr>
                      <Td>{props.site.code}</Td>
                    </Tr>
                  </>
                )}
                <Tr>
                  <Td fontWeight="bold">Address</Td>
                </Tr>
                <Tr>
                  <Td>
                    {` ${props.site.address1}, ${props.site.address2} ${props.site.city}, ${props.site.state}, ${props.site.country} ${props.site.postal_code}`
                      .replaceAll(" ,", "")
                      .trim() || "Not Entered"}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Contact Name</Td>
                </Tr>
                <Tr>
                  <Td>{props.site.contact_name || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Contact Email</Td>
                </Tr>
                <Tr>
                  <Td>{props.site.contact_email || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Contact Phone</Td>
                </Tr>
                <Tr>
                  <Td>{props.site.contact_phone || "None"}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Prompt</Td>
                </Tr>
                <Tr>
                  <Td>
                    {(props.site.prompt &&
                      props.site.prompt
                        .split("\n")
                        .map((v) => <p className="text-wrap max-w">{v}</p>)) ||
                      "None"}
                  </Td>
                </Tr>
                {props.site.notes && (
                  <>
                    <Tr>
                      <Td fontWeight="bold">Notes</Td>
                    </Tr>
                    <Tr>
                      <Td maxW="xl" sx={{ textWrap: "balance" }}>
                        {props.site.notes}
                      </Td>
                    </Tr>
                  </>
                )}
              </Tbody>
            </Table>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Asset</Th>
                  <Th fontWeight="bold">Sent On {"->"} Received On</Th>
                  <Th fontWeight="bold">Tracking Number</Th>
                  <Th fontWeight="bold">Notes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.site.assets.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link
                        href={`/${c.pump_id ? "pumps" : "kits"}?id=${
                          c.pump_id || c.kit_id
                        }&mode=2`}
                        isExternal
                      >
                        {c.pump_id ? "Pump" : "Kit"}
                        {" #"}
                        {c.number}
                        <ExternalLinkIcon mx="2px" />{" "}
                      </Link>
                    </Td>
                    <Td>
                      {" "}
                      {`${c.date_sent} -> ${c.date_received || "In-Transit"}`}
                    </Td>
                    <Td>{c.tracking_number}</Td>
                    <Td maxW="xl" sx={{ textWrap: "balance" }}>
                      {c.notes}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Asset</Th>
                  <Th fontWeight="bold">In/Out</Th>
                  <Th fontWeight="bold">Sent On {"->"} Received On</Th>
                  <Th fontWeight="bold">Tracking Number</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.site.transactions.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link
                        href={`/${c.pump_id ? "pumps" : "kits"}?id=${
                          c.pump_id || c.kit_id
                        }&mode=2`}
                        isExternal
                      >
                        {c.pump_id
                          ? c.pump_name?.toLowerCase().startsWith("pump")
                            ? c.pump_name
                            : `Pump ${c.pump_name}`
                          : `Kit #${c.kit_number}`}
                        <ExternalLinkIcon mx="2px" />{" "}
                      </Link>
                    </Td>
                    <Td>{props.site?.id === c.to ? "In" : "Out"}</Td>
                    <Td>
                      {`${c.date_sent} -> ${c.date_received || "In-Transit"}`}
                    </Td>
                    <Td>{c.tracking_number}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Patient ID</Th>
                  <Th fontWeight="bold">Gender</Th>
                  <Th fontWeight="bold">Age</Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.site.patients.map((c) => (
                  <Tr key={c.id}>
                    <Td>
                      <Link
                        href={`/patients?id=${c.id}&query=${c.id}&project_id=${props.site?.project_id}&mode=1`}
                        isExternal
                      >
                        {c.id}
                        <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Td>
                    <Td>{c.sex_at_birth === 1 ? "Male" : "Female"}</Td>
                    <Td>{c.age}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
        <TabPanel>
          <Center>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th fontWeight="bold">Filename</Th>
                  <Th fontWeight="bold">Uploaded By</Th>
                  <Th fontWeight="bold">Uploaded At</Th>
                  <Th fontWeight="bold">Notes</Th>
                  <Th fontWeight="bold"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {props.site.files.map((c) => (
                  <Tr key={c.id}>
                    <Td>{c.name}</Td>
                    <Td>{c.uploaded_by}</Td>
                    <Td>{c.created_at.slice(0, 19).replace("T", " ")}</Td>
                    <Td maxW="xl" sx={{ textWrap: "balance" }}>
                      {c.notes}
                    </Td>
                    <Td>
                      <IconButton
                        size="xs"
                        icon={<FaDownload />}
                        variant="tertiary"
                        title={"Download"}
                        aria-label={"Download"}
                        onClick={() => {
                          get_file_link(c.id).then((res) => {
                            window.open(res.data, "_newtab");
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
