import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import { demoRoutes, getRoutes, getNonLoggedRoutes } from "./routes";
import { getPermissionLevel, getPermissionList } from "./token";
import "./output.css"

const demo = process.env.REACT_APP_DEMO === "1";
const proTheme = extendTheme(theme);
const finalTheme = extendTheme(
  {
    colors: { ...proTheme.colors, brand: proTheme.colors.blue },
  },
  proTheme,
);

const level = getPermissionLevel();
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {(demo && demoRoutes) ||
        (level === 4 ? getRoutes(getPermissionList()) : getNonLoggedRoutes(level))}
    </>,
  ),
);

const root = document.getElementById("root");
if (root != null) {
  ReactDOM.createRoot(root).render(
      <ChakraProvider theme={finalTheme}>
        <RouterProvider router={router} />
      </ChakraProvider>
  );
}
