import {
  Badge,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ReactElement, useEffect, useState } from "react";
import { FiRefreshCw, FiSearch } from "react-icons/fi";
import { Pagination, useSearch } from "../../common/Pages";
import { MyTable, simplify } from "./MemberTable";
import { AddUserSchema, EditUserSchema, User } from "../../common/Models";
import {
  add_user,
  delete_user,
  edit_user,
  get_users,
  reset_mfa,
} from "../../common/Api";
import { GeneralModal } from "./Modal";
import { useFormik } from "formik";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { LoadingSpinner } from "../../common/Components";

enum ModalModes {
  ADD,
  EDIT,
}

export function UserManagement() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, pageSize, mode, , query, sort] = useSearch();

  const [totalCount, setTotalCount] = useState(1);

  const [users, setUsers] = useState(new Array<User>());

  const [queryBuffer, setQueryBuffer] = useState(query as string)

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setSearchParams((params) => {
        params.set("mode", "-1");
        params.set("email", "");
        createForm.resetForm();
        return params;
      });
    },
  });

  const createForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      permissions: [0, 0, 0, 0, 0, 0],
    },
    validationSchema: AddUserSchema,
    onSubmit: (values) => {
      toast.promise(
        add_user(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Created" },
          error: {
            title: "Failed to Create",
            description: "Please contact support",
          },
          loading: { title: "Creating..." },
        },
      );
    },
  });
  const editForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      permissions: [0, 0, 0, 0, 0, 0],
    },
    validationSchema: EditUserSchema,
    onSubmit: (values) => {
      toast.promise(
        edit_user(values).then(() => {
          setSearchParams((params) => {
            params.set("mode", "-1");
            return params;
          });
        }),
        {
          success: { title: "Successfully Modified" },
          error: {
            title: "Failed to Modify",
            description: "Please contact support",
          },
          loading: { title: "Modifying..." },
        },
      );
    },
  });

  useEffect(() => {
    switch (mode) {
      case ModalModes.ADD:
        createForm.setValues({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          permissions: [0, 0, 0, 0, 0, 0],
        });
        break;
      case ModalModes.EDIT:
        const email = searchParams.get("email") || "";
        const user = users.filter((v) => v.email === email)[0];
        if (!user) return;
        editForm.setValues({
          firstName: user.firstname || "",
          lastName: user.lastname || "",
          email: user.email || "",
          phone: user.phone || "",
          permissions: user.permissions || [0, 0, 0, 0, 0, 0],
        });
        break;
      case -1:
        return;
    }
    onOpen();
  }, [searchParams, setSearchParams, users]);

  useEffect(() => {
    setIsLoading(true);
    get_users(page as number, pageSize as number, query as string, sort as string)
      .then((res) => {
        const d = res.data.users as Array<User>;
        const t = res.data.total;
        setUsers(d);
        setTotalCount(t);
      })
      .catch(() => {
        toast({ title: "Failed to Get", status: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, query, mode, sort]);

  if (isLoading) {
    return (
      <Container
        textAlign={"center"}
        alignSelf={"center"}
        py={{ base: "4", md: "8" }}
        px={{ base: "0", md: 8 }}
      >
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
      <Box borderRadius={{ base: "none", md: "lg" }}>
        <Stack spacing="5">
          <Box px={{ base: "4", md: "6" }} pt="5">
            <Stack
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  onKeyUp={(e) => {
                    if (e.key !== "Enter") return;

                    setSearchParams((params) => {
                      params.set("query", queryBuffer);
                      params.set("page", "1")
                      return params;
                    });
                  }}
                  autoFocus={true}
                  onChange={(e) => {
                    setQueryBuffer(e.target.value);
                  }}
                  value={queryBuffer}
                />
              </InputGroup>
              <Button
                alignSelf="end"
                onClick={() => {
                  setSearchParams((params) => {
                    params.set("email", "");
                    params.set("mode", ModalModes.ADD.toString());
                    return params;
                  });
                }}
                id="add"
              >
                Add User
              </Button>
            </Stack>
          </Box>
          <Box overflowX="auto">
            <MyTable<User>
              sortColumn={sort as string}
              setSearchParams={setSearchParams}
              columns={[
                { name: "Email", width: "20%", sort_id: "email" },
                { name: "Name", width: "20%", sort_id: "name" },
                { name: "Phone", width: "15%", sort_id: "phone" },
                { name: "Activated", width: "15%", sort_id: "activated" },
                { name: "Actions", width: "15%" },
              ]}
              data={users}
              bindings={[
                (r: User) => r.email,
                (r: User) => (r.firstname || "") + " " + (r.lastname || ""),
                (r: User) => r.phone,
                (r: User) => (
                  <Badge size="sm" colorScheme={r.activated ? "green" : "red"}>
                    {r.activated ? "Activated" : "Not Activated"}
                  </Badge>
                ),
              ]}
              rk={(r: User) => r.email}
              functions={[
                {
                  title: "Edit",
                  icon: <FiEdit2 />,
                  fn: (u: User) => {
                    setSearchParams((params) => {
                      params.set("email", u.email);
                      params.set("mode", ModalModes.EDIT.toString());
                      return params;
                    });
                  },
                },
                {
                  title: "Reset MFA",
                  icon: <FiRefreshCw />,
                  fn: (u) => {
                    const yes = window.confirm(
                      `Are you sure you want to reset multi-factor authentication for the user "${u.email}"? This action will remove all MFA devices attached to the account and the user will have to re-enable MFA on their next login`,
                    );

                    if (!yes) return;

                    reset_mfa(u.email)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch(() => {});
                  },
                },
                {
                  title: "Delete",
                  icon: <FiTrash2 color="darkred" />,
                  fn: (u) => {
                    const yes = window.confirm(
                      `Are you sure you want to delete the user "${u.email}"? This action cannot be undone`,
                    );

                    if (!yes) return;

                    toast.promise(
                      delete_user(u.email).then(() => {
                        setSearchParams((params) => {
                          params.set("mode", "-1");
                          return params;
                        });
                      }),
                      {
                        success: { title: "Successfully Deleted" },
                        error: {
                          title: "Failed to Delete Tube",
                          description: "Please contact support",
                        },
                        loading: { title: "Deleting..." },
                      },
                    );
                  },
                },
              ]}
            />
          </Box>
          <Box bg="bg.surface">
            <Container>
              <Pagination
                count={totalCount}
                pageSize={pageSize as number}
                siblingCount={2}
                page={page as number}
                onChange={(e: any) => {
                  setSearchParams((params) => {
                    params.set("page", e.page);
                    return params;
                  });
                }}
              />
            </Container>
          </Box>
        </Stack>
      </Box>
      {mode === ModalModes.ADD && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={createForm.handleSubmit}
          body={<AddUserElement formik={createForm} disabled={[]} />}
          title={"Add User"}
          buttonText="Save"
        />
      )}
      {mode === ModalModes.EDIT && (
        <GeneralModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          handleSubmit={editForm.handleSubmit}
          body={<AddUserElement formik={editForm} disabled={["email"]} />}
          title={"Edit User"}
          buttonText="Save"
        />
      )}
    </Container>
  );
}

function AddUserElement(props: any): ReactElement {
  const formik = props.formik;
  const disabled: string[] = props.disabled;

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{ base: "5", lg: "8" }}
        justify="space-between"
      >
        <Box m="0 auto" bg="bg.surface" borderRadius="lg">
          <Stack
            spacing="5"
            px={{ base: "4", md: "6" }}
            py={{ base: "5", md: "6" }}
          >
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="email"
                isInvalid={formik.errors.email && formik.touched.email}
                isDisabled={disabled.filter((v) => v === "email").length !== 0}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="phone"
                isInvalid={formik.errors.phone && formik.touched.phone}
                isDisabled={disabled.filter((v) => v === "phone").length !== 0}
              >
                <FormLabel>Phone</FormLabel>
                <InputGroup>
                  <InputLeftAddon>+1</InputLeftAddon>
                  <Input
                    id="phone"
                    name="phone"
                    type="tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                </InputGroup>
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="6" direction={{ base: "column", md: "row" }}>
              <FormControl
                id="firstName"
                isInvalid={formik.errors.firstName && formik.touched.firstName}
                isDisabled={
                  disabled.filter((v) => v === "firstName").length !== 0
                }
              >
                <FormLabel>First Name</FormLabel>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="lastName"
                isInvalid={formik.errors.lastName && formik.touched.lastName}
                isDisabled={
                  disabled.filter((v) => v === "lastName").length !== 0
                }
              >
                <FormLabel>Last Name</FormLabel>
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Container maxW="lg">
              <FormLabel>Permissions</FormLabel>
              <Table>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Read</Th>
                    <Th>Write</Th>
                    <Th>Delete</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {["Users", "Patients", "Raw Data", "Sites", "Assets", "Audit"].map((v, i) => (
                    <Tr key={v}>
                      <Td>{v}</Td>
                      {["read", "write", "delete"].map((vv, j) => (
                        <Td key={j}>
                          <Checkbox
                            id={`${simplify(v)}-${vv}`}
                            onChange={() => {
                              const final = formik.values.permissions;
                              const val =
                                formik.values.permissions[i] ^ (1 << j);
                              final[i] = val;
                              formik.setValues({
                                ...formik.values,
                                permissions: final,
                              });
                            }}
                            isChecked={
                              (formik.values.permissions[i] & (1 << j)) !== 0
                            }
                          ></Checkbox>
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Container>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

