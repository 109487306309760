import { IconButton, TableProps, Text } from "@chakra-ui/react";
import { MouseEventHandler, ReactElement } from "react";

export interface TableFn<T> {
  title: string;
  icon: ReactElement;
  fn: (row: T) => any;
  condition?: (row: T) => boolean;
}

function percentageToClass(w: string): string {
  const map = {
    "5%" : "w-[5%]",
    "10%" : "w-[10%]",
    "15%" : "w-[15%]",
    "20%" : "w-[20%]",
    "25%" : "w-[25%]",
    "30%" : "w-[30%]",
    "35%" : "w-[35%]",
    "40%" : "w-[40%]",
    "45%" : "w-[45%]",
    "50%" : "w-[50%]",
    "55%" : "w-[55%]",
    "60%" : "w-[60%]",
    "65%" : "w-[65%]",
    "70%" : "w-[70%]",
    "75%" : "w-[75%]",
    "80%" : "w-[80%]",
    "85%" : "w-[85%]",
    "90%" : "w-[90%]",
    "95%" : "w-[95%]",
  }

  return map[w as keyof typeof map]
}

export function MyTable<T>(
  props: TableProps & {
    columns: { name: string; width: string; sort_id?: string }[];
    data: T[];
    bindings: ((row: T) => any)[];
    functions: TableFn<T>[];
    rk: (row: T) => string;
    sortColumn: string;
    setSearchParams: Function;
    rowAction?: (row: T) => any;
  },
) {
  const {
    rk,
    sortColumn,
    setSearchParams,
    functions,
    columns,
    data,
    bindings,
    ...tp
  } = props;

  return (
    <table
      id="mainTable"
      className="w-full table-fixed text-sm text-left text-gray-900 dark:text-gray-400"
    >
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
        <tr id="headerRow">
          {props.columns.map((c) => (
            <th
              key={c.name}
              scope="col"
              className={
                (c.sort_id ? "cursor-pointer" : "") +
                `${percentageToClass(c.width)}` +
                " p-3" +
                (c.name == "Actions" ? " text-center" : "")
              }
              id={`${simplify(c.name)}-field`}
              onClick={() => {
                if (!c.sort_id || c.sort_id === null) return;

                props.setSearchParams((params: any) => {
                  const cur = params.get("sort");
                  var res = `+${c.sort_id}`;
                  if (cur === `+${c.sort_id}`) res = `-${c.sort_id}`;
                  if (cur === `-${c.sort_id}`) res = "";
                  params.set("sort", res);
                  return params;
                });
              }}
            >
              {c.name}
              <Text display="contents" fontSize="lg" key={c.name + "_sort"}>
                {props.sortColumn &&
                c.sort_id &&
                props.sortColumn.slice(1) === c.sort_id
                  ? props.sortColumn.startsWith("+")
                    ? " ▴"
                    : " ▾"
                  : ""}
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data &&
          props.data.length !== 0 &&
          props.data.map((r: T, j: number) => (
            <tr
              key={props.rk(r)}
              id={`row-${j}`}
              className={
                "border-b dark:border-gray-700 hover:bg-gray-200" +
                (props.rowAction ? " hover:cursor-pointer" : "")
              }
              onClick={(e) => {
                if (!props.rowAction) return;
                props.rowAction(r);
              }}
            >
              {props.bindings.map((b, i) => (
                <td
                  className={
                    // `w-${percentageToClass(props.columns[i].width)}` +
                    "px-4 py-3"
                  }
                  key={i}
                  id={`cell-${j}-${simplify(props.columns[i].name)}`}
                >
                  {b(r)}
                </td>
              ))}

              {props.functions && props.functions.length > 0 && (
                <td className="justify-end text-center">
                  {props.functions
                    .filter((f) => !f.condition || f.condition(r))
                    .map((f) => {
                      return (
                        <IconButton
                          id={`cell-${j}-${simplify(f.title)}-btn`}
                          key={f.title}
                          icon={f.icon}
                          variant="tertiary"
                          title={f.title}
                          aria-label={f.title}
                          onClick={(e) => {
                            e.stopPropagation();
                            f.fn(r);
                          }}
                        />
                      );
                    })}
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export function simplify(s: string): string {
  return s.replaceAll(" ", "_").toLowerCase();
}
